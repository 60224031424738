import React, { useState } from "react";
import "./EditFormUser.css"; // Importa tu archivo CSS

const EditFormUser = ({ data, table, onClose, onUpdate, isAdding }) => {
  const fieldsToEdit = ["Id_usuario", "mac", "usuario", "password", "email"];
  const [editedData, setEditedData] = useState(isAdding ? {} : { ...data });
  const [showWarning, setShowWarning] = useState(false);
  const [fieldChanges, setFieldChanges] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
    setFieldChanges((prevChanges) => ({ ...prevChanges, [name]: true }));
  };

  const handleSave = () => {
    if (isAdding || showWarning) {
      if (window.confirm("¿Estás seguro de que deseas guardar los cambios?")) {
        if (isAdding) {
          handleCreate();
        } else {
          handleUpdate();
        }
      } else {
        setShowWarning(false); // SetShowWarning a false para que no se muestre el mensaje
      }
    } else {
      setShowWarning(true);
    }
  };

  const handleCreate = () => {
    // Código de creación similar al tuyo
  };

  const handleUpdate = () => {
    fetch(
      `https://www.carelhue.com.ar/php/userchangeconfig.php?action=edit${table}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedData),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          onUpdate(editedData);
          onClose();
          window.location.href = "https://www.carelhue.com.ar/Usuarios2"; // Redirección
        }
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  return (
    <div className="edit-form-center">
      <div className="edit-form-container">
        <h3 style={{ color: "#ffffff", textAlign: "center" }}>
          {isAdding ? "Agregar Registro" : "Editar Registro"}
        </h3>
      <form>
        {fieldsToEdit.map((field) => (
          <div
            key={field}
            className={`form-field ${
              field === "Id_usuario" || field === "mac"
                ? "non-editable"
                : fieldChanges[field]
                ? "editable-modified"
                : "editable"
            }`}
          >
            <table>
              <tr>
                <td>
                  <label style={{ color: "#ffff", fontSize: "14px" }}>
                    {field}
                  </label>
                </td>
                <td style={{ textAlign: "right" }}>
                  <input
                    type="text"
                    name={field}
                    value={editedData[field] || ""}
                    onChange={handleInputChange}
                    readOnly={field === "Id_usuario" || field === "mac"}
                  />
                </td>
              </tr>
            </table>
          </div>
        ))}
       </form>
        <button onClick={handleSave}>
          {isAdding ? "Agregar" : "Guardar Cambios"}
        </button>
        <button onClick={onClose}>Cancelar</button>
        {showWarning && (
          <div className="warning-message">
            <p>¡Advertencia! Los cambios se guardarán.</p>
            <button onClick={() => setShowWarning(false)}>Cancelar</button>
            <button onClick={handleSave}>Guardar</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditFormUser;
