import React, { useState, useEffect } from "react";
import "./cursos.css";

const Cursos = () => {
  const [loading, setLoading] = useState(true);
  const [cursos, setCursos] = useState([]);

  useEffect(() => {
    // Hacer la solicitud para obtener los cursos desde el servidor PHP
    fetch("https://www.carelhue.com.ar/php/cursos.php")
      .then((response) => response.json())
      .then((data) => {
        setCursos(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error al obtener los cursos:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="containercursos">
      <div >
      <div
        style={{
          margin: "100px 0% 0% 5%",
        }}
      >
        
      </div>
      <h4>Cursos<i className="fas fa-graduation-cap" style={{paddingLeft:"20px", color:"#0c5da1"}}></i> </h4>
        <br />
      </div>
      <div className="cursosGrid">
        {loading ? (
          <p>Cargando cursos...</p>
        ) : (
          cursos.map((curso) => (
            <div key={curso.id} className="cursoCard">
              <img
                src={curso.imagen}
                alt={curso.curso}
                className="cursoImagen"
              />
              <h4 className="cursoTitulo">{curso.curso.toUpperCase()}</h4>
              <p className="cursoDescripcion">{curso.descripcion}</p>
              <div className="cursoInfo">
                <div className="cursoDetalle">
                  <i className="fas fa-calendar"></i>
                  <p>Inicio: {curso.inicio}</p>
                </div>
                <div className="cursoDetalle">
                  <i className="fas fa-clock"></i>
                  <p>Duración: {curso.dura}</p>
                </div>
              </div>
              <div className="cursoEnlace">
                <a
                  href={curso.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="boton-link"
                >
                  <i className="fas fa-video"></i> Ver curso
                </a>
              </div>
            </div>
          ))
        )}
      </div>
      <br/><br/><br/><br/><br/>
      <div className="footer">
        <p>CARELHUE - Tecnología en hidroponía</p>
      </div>
    </div>
  );
};

export default Cursos;
