import React, { useState } from "react";
//import { Image } from "./image";

export const Gallery = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const openModal = (src) => {
    setImageSrc(src);
    setShowModal(true);
  };

  const closeModal = () => {
    setImageSrc("");
    setShowModal(false);
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="portfolioback">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="section-title">
          <h2 style={{ textAlign: "center" }}>Imagenes</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="portfolio-items">
              {props.data
                ? props.data.map((d, i) => {
                    // Calcula las proporciones de la imagen original
                    // const aspectRatio =
                    //  d.largeImage.width / d.largeImage.height;
                    // Define el ancho y alto de la imagen ajustados al tamaño deseado
                    //const width = 100;
                    //const height = width / aspectRatio;
                    return (
                      <div
                        key={`${d.title}-${i}`}
                        className="col-sm-6 col-md-4 col-lg-4"
                      >
                        <div>
                          <img
                            src={d.smallImage}
                            className="portfolio-img"
                            alt={d.title}
                            width={250}
                            height={250}
                            style={{ borderRadius: "8px 8px 8px 8px" }}
                            onClick={() => openModal(d.largeImage)}
                          />
                          {showModal && (
                            <div className="modal">
                              <span className="close" onClick={closeModal}>
                                &times;
                              </span>
                              <img src={imageSrc} alt={d.title} />
                            </div>
                          )}
                          
                        </div>
                      </div>
                    );
                  })
                : "Cargando..."}
            </div>
          </div>
        </div>
        <br></br>
      </div>
    </div>
  );
};

