import React from "react";
import { useState, useEffect } from "react";
import Admin from "./admin";

import JsonData from "../../data/data.json";

const AdminDash = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <br />
      <br />
      <div></div>

      <div>
        <Admin data={landingPageData.Admin} />
      </div>
    </div>
  );
};
export default AdminDash;
