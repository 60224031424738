import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTemperatureLow,
  faTint,
  faSun,
  faWater,
  faBolt,
  faFillDrip,
} from "@fortawesome/free-solid-svg-icons";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import Loading from "react-loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./graficos.css";

const Graficos = () => {
  const [loading, setLoading] = useState(true);
  const [pasada, setPasada] = useState(false);

  const [grafTemp, setGrafTemp] = useState([]);
  const [hwidLog, setHwidLog] = useState("");
  //const [desde, setDesde] = useState("");
  //const [hasta, setHasta] = useState("");
  const [desde, setDesde] = useState(
    new Date(localStorage.getItem("fechaDesde"))
  );
  const [hasta, setHasta] = useState(
    new Date(localStorage.getItem("fechaHasta"))
  );
  const [usuario, setUsuario] = useState("");

  const [maxTempData, setMaxTempData] = useState([]);
  const [minTempData, setMinTempData] = useState([]);

  const [maxHumData, setMaxHumData] = useState([]);
  const [minHumData, setMinHumData] = useState([]);

  const [maxLuzData, setMaxLuzData] = useState([]);
  const [minLuzData, setMinLuzData] = useState([]);

  const [maxTsnData, setMaxTsnData] = useState([]);
  const [minTsnData, setMinTsnData] = useState([]);
  const [maxEcData, setMaxEcData] = useState([]);
  const [minEcData, setMinEcData] = useState([]);
  const [maxTnkData, setMaxTnkData] = useState([]);
  const [minTnkData, setMinTnkData] = useState([]);
  const [PromTemp, setPromTemp] = useState([]);
  const [PromHum, setPromHum] = useState([]);
  const [PromLuz, setPromLuz] = useState([]);
  const [PromTsn, setPromTsn] = useState([]);
  const [PromEc, setPromEc] = useState([]);
  const [PromTnk, setPromTnk] = useState([]);
  const [ultimoRegistro, setultimoRegistro] = useState([]);

  const DatosGrafTemp = useCallback(() => {
    setLoading(true);

    console.log("GrafTemp hwid ===>" + String(hwidLog));
    console.log("GrafTemp desde ===>" + desde);
    console.log("GrafTemp hasta ===>" + hasta);
    //fetch("http://localhost/carelhue/php/graficas.php", {
    fetch("https://www.carelhue.com.ar/php/graficas.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ hwid: hwidLog, desd: desde, hast: hasta }),
    })
      .then((res) => res.json()) // Convertir la respuesta a un objeto JSON
      .then((data) => {
        const res = data;
        setGrafTemp(res);
        console.log(grafTemp);
        console.log("grafTemp.length:" + grafTemp.length);
        if (grafTemp.length > 0) {
          console.log("Ingresa a REDUCETEMP, RESULT:");
          let grafTemp1 = [...grafTemp]; // Copia de grafTemp
          const redMaxLuz = grafTemp1.reduce((a, b) =>
            Number(a.iluz) > Number(b.iluz) ? a : b
          );
          const redMinLuz = grafTemp1.reduce((c, d) =>
            Number(c.iluz) < Number(d.iluz) ? c : d
          );
          const redpromLuz = (
            grafTemp1.reduce((acc, cur) => acc + +cur.iluz, 0) /
            grafTemp1.length
          ).toFixed(1);
          let grafTemp2 = [...grafTemp]; // Copia de grafTemp
          const redMaxTemp = grafTemp2.reduce((e, f) =>
            Number(e.temp) > Number(f.temp) ? e : f
          );
          const redMinTemp = grafTemp2.reduce((g, h) =>
            Number(g.temp) < Number(h.temp) ? g : h
          );
          const redpromTemp = (
            grafTemp2.reduce((acc, cur) => acc + +cur.temp, 0) /
            grafTemp2.length
          ).toFixed(1);
          let grafTemp3 = [...grafTemp]; // Copia de grafTemp
          const cantidadRegistros = Array.isArray(grafTemp3)
            ? grafTemp3.length
            : 0;
          console.log(
            "Cantidad de registros de GRAFTEMP3: " + cantidadRegistros
          );
          const redMaxHum = grafTemp3.reduce((i, j) =>
            Number(i.hum) > Number(j.hum) ? i : j
          );
          const redMinHum = grafTemp3.reduce((k, l) => (k.hum < l.hum ? k : l));
          const redpromHum = (
            grafTemp3.reduce((acc, cur) => acc + +cur.hum, 0) / grafTemp3.length
          ).toFixed(1);
          console.log("redmaxHum: " + redMaxHum.hum);
          console.log("redMinHum" + redMinHum.hum);
          console.log("redpromHum:" + redpromHum);

          let grafTemp4 = [...grafTemp]; // Copia de grafTemp
          const redMaxTsn = grafTemp4.reduce((i, j) =>
            Number(i.temp_sn) > Number(j.temp_sn) ? i : j
          );
          const redMinTsn = grafTemp4.reduce((k, l) =>
            k.temp_sn < l.temp_sn ? k : l
          );
          const redpromTsn = (
            grafTemp4.reduce((acc, cur) => acc + +cur.temp_sn, 0) /
            grafTemp4.length
          ).toFixed(1);
          console.log("redmaxTsn: " + redMaxTsn.temp_sn);
          console.log("redMinTsn" + redMinTsn.temp_sn);
          console.log("redpromTsn:" + redpromTsn);

          let grafTemp5 = [...grafTemp]; // Copia de grafTemp
          const redMaxEc = grafTemp5.reduce((i, j) =>
            Number(i.ec) > Number(j.ec) ? i : j
          );
          const redMinEc = grafTemp5.reduce((k, l) =>
            Number(k.ec) < Number(l.ec) ? k : l
          );
          const redpromEc = (
            grafTemp5.reduce((acc, cur) => acc + +cur.ec, 0) / grafTemp5.length
          ).toFixed(1);
          console.log("redmaxEc: " + redMaxEc.ec);
          console.log("redMinEc" + redMinEc.ec);
          console.log("redpromEc:" + redpromEc);

          let grafTemp6 = [...grafTemp]; // Copia de grafTemp
          const redMaxTnk = grafTemp6.reduce((i, j) =>
            Number(i.tank) > Number(j.tank) ? i : j
          );
          const redMinTnk = grafTemp6.reduce((k, l) =>
            k.tank < l.tank ? k : l
          );
          const redpromTnk = (
            grafTemp6.reduce((acc, cur) => acc + +cur.tank, 0) /
            grafTemp6.length
          ).toFixed(1);
          console.log("redmaxTnk: " + redMaxTnk.tank);
          console.log("redMinTnk" + redMinTnk.tank);
          console.log("redpromTnk:" + redpromTnk);

          const ultimoRegistro = grafTemp6[grafTemp6.length - 1];

          console.log(redMaxTemp);
          setMaxTempData(redMaxTemp);
          setMinTempData(redMinTemp);
          setPromTemp(redpromTemp);
          setMaxHumData(redMaxHum);
          setMinHumData(redMinHum);
          setPromHum(redpromHum);
          setMaxLuzData(redMaxLuz);
          setMinLuzData(redMinLuz);
          setPromLuz(redpromLuz);

          setMaxTsnData(redMaxTsn);
          setMinTsnData(redMinTsn);
          setPromTsn(redpromTsn);
          setMaxEcData(redMaxEc);
          setMinEcData(redMinEc);
          setPromEc(redpromEc);
          setMaxTnkData(redMaxTnk);
          setMinTnkData(redMinTnk);
          setPromTnk(redpromTnk);
          setultimoRegistro(ultimoRegistro);
        } else {
          console.log("ELSE REDUCETEMP, RESULT:");
          const redMaxTemp = [
            {
              ec: "0.30",
              fechayhora: "2020-04-25",
              hum: "99",
              id: "001",
              iluz: "50",
              tank: "50",
              temp: "5",
              temp_sn: "10",
              hora: "10",
              minuto: "05",
            },
          ];
          console.log(redMaxTemp);
        }
        setLoading(false);
        setPasada(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [desde, hasta, hwidLog, grafTemp]);

  useEffect(() => {
    setUsuario(localStorage.getItem("userName"));
    //setDesde(localStorage.getItem("fechaDesde"));
    //setHasta(localStorage.getItem("fechaHasta"));
    setHwidLog(localStorage.getItem("macData"));

    console.log("Graficos===>" + usuario);
    console.log("Graficos===>" + hwidLog);
    console.log("Graficos===>" + desde);
    console.log("Graficos===>" + hasta);
    if (pasada === false) {
      DatosGrafTemp();
    }
  }, [DatosGrafTemp, desde, hasta, hwidLog, usuario, pasada]);
  /*********************************************************************************************************************** */
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
          }}
        >
          <p>
            <strong>Temperatura, Humedad y Luz</strong>
          </p>
          <p>
            <u>Fecha y hora</u> {`: ${data.fechayhora}`}
          </p>
          <p className="temp">
            <span style={{ color: "#FEBE00" }}>█</span>
            <u>Temp</u>
            {`: ${data.temp} °C`}
          </p>
          <p className="hum">
            <span style={{ color: "#5882FA" }}>█</span>
            <u>Hum</u>
            {`: ${data.hum} %`}
          </p>
          <p className="luz">
            <span style={{ color: "#FA5858" }}>█</span>
            <u>Luz</u>
            {`: ${data.iluz} %`}
          </p>
        </div>
      );
    }

    return null;
  };
  const CustomTemp = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
          }}
        >
          <p>
            <strong>Temperatura</strong>
          </p>
          <p>
            <u>Fecha y hora</u> {`: ${data.fechayhora}`}
          </p>
          <p>
            <u>Temp</u>
            {`: ${data.temp}ºC.`}
          </p>
        </div>
      );
    }

    return null;
  };

  const CustomHumidity = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>
            <strong>Humedad</strong>
          </p>
          <p>
            <u>Fecha y hora</u> {`: ${data.fechayhora}`}
          </p>
          <p>
            <u>Hum</u>
            {`: ${data.hum}%.`}
          </p>
        </div>
      );
    }

    return null;
  };

  const CustomTSN = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>
            <strong>Temperatura SN</strong>
          </p>
          <p>
            <u>Fecha y hora</u> {`: ${data.fechayhora}`}
          </p>
          <p>
            <u>Temp</u>
            {`: ${data.temp}ºC.`}
          </p>
        </div>
      );
    } 

    return null;
  };

  const CustomEC = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>
            <strong>EC</strong>
          </p>
          <p>
            <u>Fecha y hora</u> {`: ${data.fechayhora}`}
          </p>
          <p>
            <u>EC</u>
            {`: ${data.ec}Ms`}
          </p>
        </div>
      );
    }

    return null;
  };

  const CustomTank = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>
            <strong>Nivel Tanque SN</strong>
          </p>
          <p>
            <u>Fecha y hora</u> {`: ${data.fechayhora}`}
          </p>
          <p>
            <u>Nivel</u>
            {`: ${data.tank}%`}
          </p>
        </div>
      );
    }

    return null;
  };
  const CustomLightIntensity = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>
            <strong>Luz Recibida</strong>
          </p>
          <p>
            <u>Fecha y hora</u> {`: ${data.fechayhora}`}
          </p>
          <p>
            <u>Temp</u>
            {`: ${data.iluz}%`}
          </p>
        </div>
      );
    }

    return null;
  };
  const CustomIrrigationPeriods = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>
            <strong>Irrigacion</strong>
          </p>
          <p>
            <u>Fecha y hora</u> {`: ${data.fechayhora}`}
          </p>
          <p>
            <u>Irriga</u>
            {`: ${data.irriga}`}
          </p>
          <p>{`Fecha y hora: ${data.fechayhora}`}</p>
          <p>{`Periodos de Irrigación: ${data.irriga}`}</p>
        </div>
      );
    }

    return null;
  };
  const CustomO2 = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>
            <strong>Aireacion</strong>
          </p>
          <p>
            <u>Fecha y hora</u> {`: ${data.fechayhora}`}
          </p>
          <p>
            <u>Aireacion</u>
            {`: ${data.erelay2}`}
          </p>
        </div>
      );
    }

    return null;
  };

  /******************************************************************************************************************************************************** */
  return (
    <div>
      <div className="centro" style={{ alignContent: "center" }}>
        <div>
          <h4 className="centro">GRAFICOS <i className="fas fa-chart-bar icono" style={{color:"#b87f05"}} ></i></h4>
        </div>
        <h6>MAC:{hwidLog}</h6>
        <div className="divdatepicker">
          <span className="spandsd">{" Desde:"}</span>
          <DatePicker 
            selected={desde}
            onChange={(date) => setDesde(date)}
            dateFormat="yyyy/MM/dd"
            className="date-picker" // Aplica la clase CSS aquí
          />
          <span className="spanhst">{" Hasta:"}</span>
          <DatePicker
            selected={hasta}
            onChange={(date) => setHasta(date)}
            dateFormat="yyyy/MM/dd"
            className="date-picker" // Aplica la clase CSS aquí
          />{"  "}{"  "}
          <button className="btnsbuscar" onClick={DatosGrafTemp}>
            Buscar
          </button>
        </div>
        {/* <p>
          <h6>
            MAC:{hwidLog} Rango de fechas desde:{desde} hasta:{hasta}
          </h6>
        </p> */}
        <p>Se obtuvieron <span className="resaltar">{grafTemp.length}</span> registros para armar los graficos</p>
        <div style={{ textAlign: "center" }}>
          <h4>Ultimos datos recibidos</h4>
        </div>
        <div
          className="itemlast"
          style={{
            border: "2px solid #D1FAD0  ",
            backgroundColor: "#D1FAD0  ",
            boxShadow: "5px 5px 5px #454e4794", /* Agregado un sombreado suave */
            borderRadius: "10px", /* Radio de redondeo de 20px para todas las esquinas */
          }}
        >
          <div>
            {loading ? (
              <div className="loading-maxmin" >
                <Loading type="spin" color="#0592F9" height={50} width={50} />
              </div>
            ) : (
              <p>
                <u>Temperatura:</u>
                <strong style={{ fontSize: "1.2em" }}>
                  {" "}
                  {ultimoRegistro.temp}°C{" "}
                </strong>
                <u>Humedad:</u>
                <strong style={{ fontSize: "1.2em" }}>
                  {" "}
                  {ultimoRegistro.hum}%{" "}
                </strong>
                <u>EC:</u>
                <strong style={{ fontSize: "1.2em" }}>
                  {" "}
                  {ultimoRegistro.ec}mS{" "}
                </strong>
                <u>TanqueSN:</u>
                <strong style={{ fontSize: "1.2em" }}>
                  {" "}
                  {ultimoRegistro.tank}%{" "}
                </strong>
                <u>Irrigacion:</u>
                <strong style={{ fontSize: "1.2em" }}>
                  {" "}
                  {ultimoRegistro.erelay1}{" "}
                </strong>

                <u>Fecha:</u>
                <strong style={{ fontSize: "1.2em" }}>
                  {" "}
                  {ultimoRegistro.fechayhora}{" "}
                </strong>
              </p>
            )}
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <h4>Maximas Minimas y Promedios</h4>
        </div>
        <div className="container">
          {/* LUZ LUZ LUZ LUZ LUZ LUZ LUZ LUZ LUZ LUZ  */}
          <div
            className="item"
            style={{
              border: "2px solid #FFEE58  ",
              backgroundColor: "#FFEE58  ",
            }}
          >
            <h5>
              LUZ <FontAwesomeIcon icon={faSun} />{" "}
            </h5>

            <div>
              {loading ? (
                <div className="loading-maxmin">
                  <Loading type="spin" color="#0592F9" height={50} width={50} />
                </div>
              ) : (
                <div className="chart-maxmin">
                  {Array.isArray(grafTemp) && grafTemp.length > 0 ? (
                    <>
                      <p>
                        <u>MAX:</u>
                        <strong style={{ fontSize: "1.2em" }}>
                          {" "}
                          {maxLuzData.iluz} %
                        </strong>{" "}
                        el día {maxLuzData.dia}/{maxLuzData.mes} a las{" "}
                        {maxLuzData.hora}:{maxLuzData.minuto}
                      </p>
                      <p>
                        <u>MIN</u>
                        <strong style={{ fontSize: "1.2em" }}>
                          {" "}
                          {minLuzData.iluz} %{" "}
                        </strong>
                        el día {minLuzData.dia}/{minLuzData.mes} a las{" "}
                        {minLuzData.hora}:{minLuzData.minuto}
                      </p>
                      <p>
                        <u>Promedio:</u>
                        <strong style={{ fontSize: "1.2em" }}>
                          {" "}
                          {PromLuz}%
                        </strong>
                      </p>
                    </>
                  ) : (
                    <p>No se encontraron datos de luz.</p>
                  )}
                </div>
              )}
            </div>
          </div>

          <div
            className="item"
            style={{
              border: "2px solid  #FF8A65  ",
              backgroundColor: "#FF8A65  ",
            }}
          >
            {/* TEMPERATURA TEMPERATURA TEMPERATURA TEMPERATURA */}
            <h5>
              TEMPERATURA <FontAwesomeIcon icon={faTemperatureLow} />
            </h5>
            {loading ? (
              <div className="loading-maxmin">
                <Loading type="spin" color="#0592F9" height={50} width={50} />
              </div>
            ) : (
              <div className="chart-maxmin">
                <p>
                  <u>MAX:</u>
                  <strong style={{ fontSize: "1.2em" }}>
                    {" "}
                    {maxTempData.temp}°
                  </strong>{" "}
                  el día {maxTempData.dia}/{maxTempData.mes} a las{" "}
                  {maxTempData.hora}:{maxTempData.minuto}
                </p>
                <p>
                  <u>MIN</u>
                  <strong style={{ fontSize: "1.2em" }}>
                    {" "}
                    {minTempData.temp}°
                  </strong>
                  el día {minTempData.dia}/{minTempData.mes} a las{" "}
                  {minTempData.hora}:{minTempData.minuto}
                </p>
                <p>
                  <u>Promedio:</u>
                  <strong style={{ fontSize: "1.2em" }}> {PromTemp}°</strong>
                </p>
              </div>
            )}
          </div>

          {/* HUMEDAD HUMEDAD HUMEDAD HUMEDAD HUMEDAD HUMEDAD */}
          <div
            className="item"
            style={{
              border: "2px solid #64B5F6 ",
              backgroundColor: "#64B5F6 ",
            }}
          >
            <h5>
              HUMEDAD <FontAwesomeIcon icon={faTint} />
            </h5>
            {loading ? (
              <div className="loading-maxmin">
                <Loading type="spin" color="#0592F9" height={50} width={50} />
              </div>
            ) : (
              <div className="chart-maxmin">
                {Array.isArray(grafTemp) && grafTemp.length > 0 ? (
                  <>
                    <p>
                      <u>MAX:</u>
                      <strong style={{ fontSize: "1.2em" }}>
                        {" "}
                        {maxHumData.hum}%{" "}
                      </strong>{" "}
                      el día {maxHumData.dia}/{maxHumData.mes} a las{" "}
                      {maxHumData.hora}:{maxHumData.minuto}{" "}
                    </p>
                    <p>
                      <u>MIN</u>
                      <strong style={{ fontSize: "1.2em" }}>
                        {" "}
                        {minHumData.hum}%
                      </strong>
                      el día
                      {minHumData.dia}/{minHumData.mes} a las {minHumData.hora}:
                      {minHumData.minuto}
                    </p>
                    <p>
                      <u>Promedio:</u>
                      <strong style={{ fontSize: "1.2em" }}> {PromHum}%</strong>
                    </p>
                  </>
                ) : (
                  <p>No se encontraron datos de Humedad.</p>
                )}
              </div>
            )}
          </div>

          {/* Temperatura Solucion Nutriente Temperatura Solucion Nutriente Temperatura Solucion Nutriente */}
          <div
            className="item"
            style={{
              border: "2px solid #9ACD96  ",
              backgroundColor: "#9ACD96  ",
            }}
          >
            <h5>
              TSN <FontAwesomeIcon icon={faWater} />
            </h5>
            {loading ? (
              <div className="loading-maxmin">
                <Loading type="spin" color="#0592F9" height={50} width={50} />
              </div>
            ) : (
              <div className="chart-maxmin">
                {Array.isArray(grafTemp) && grafTemp.length > 0 ? (
                  <>
                    <p>
                      <u>MAX:</u>
                      <strong style={{ fontSize: "1.2em" }}>
                        {" "}
                        {maxTsnData.temp_sn}°C{" "}
                      </strong>{" "}
                      el día {maxTsnData.dia}/{maxTsnData.mes} a las{" "}
                      {maxTsnData.hora}:{maxTsnData.minuto}{" "}
                    </p>
                    <p>
                      <u>MIN</u>
                      <strong style={{ fontSize: "1.2em" }}>
                        {" "}
                        {minTsnData.temp_sn}°C
                      </strong>
                      el día
                      {minTsnData.dia}/{minTsnData.mes} a las {minTsnData.hora}:
                      {minTsnData.minuto}
                    </p>
                    <p>
                      <u>Promedio:</u>
                      <strong style={{ fontSize: "1.2em" }}> {PromTsn}%</strong>
                    </p>
                  </>
                ) : (
                  <p>
                    No se encontraron datos de Temperatura de Solucion
                    Nutriente.
                  </p>
                )}
              </div>
            )}
          </div>
          {/* ELECTRO CONDUCTIVIDADELECTRO CONDUCTIVIDADELECTRO CONDUCTIVIDADELECTRO CONDUCTIVIDADELECTRO CONDUCTIVIDAD */}
          <div
            className="item"
            style={{
              border: "2px solid #FFB74D  ",
              backgroundColor: "#FFB74D  ",
            }}
          >
            <h5>
              EC <FontAwesomeIcon icon={faBolt} />
            </h5>
            {loading ? (
              <div className="loading-maxmin">
                <Loading type="spin" color="#0592F9" height={50} width={50} />
              </div>
            ) : (
              <div className="chart-maxmin">
                {Array.isArray(grafTemp) && grafTemp.length > 0 ? (
                  <>
                    <p>
                      <u>MAX:</u>
                      <strong style={{ fontSize: "1.2em" }}>
                        {" "}
                        {maxEcData.ec}mS{" "}
                      </strong>{" "}
                      el día {maxEcData.dia}/{maxEcData.mes} a las{" "}
                      {maxEcData.hora}:{maxEcData.minuto}{" "}
                    </p>
                    <p>
                      <u>MIN</u>
                      <strong style={{ fontSize: "1.2em" }}>
                        {" "}
                        {minEcData.ec}mS
                      </strong>
                      el día
                      {minEcData.dia}/{minEcData.mes} a las {minEcData.hora}:
                      {minEcData.minuto}
                    </p>
                    <p>
                      <u>Promedio:</u>
                      <strong style={{ fontSize: "1.2em" }}> {PromEc}mS</strong>
                    </p>
                  </>
                ) : (
                  <p>No se encontraron datos de Electroconductividad.</p>
                )}
              </div>
            )}
          </div>
          {/* Tanque de  SN Tanque de  SN Tanque de  SN Tanque de  SN Tanque de  SN */}
          <div
            className="item"
            style={{
              border: "2px solid #D1C4E9  ",
              backgroundColor: "#D1C4E9  ",
            }}
          >
            <h5>
              Nivel de Tanque <FontAwesomeIcon icon={faFillDrip} />
            </h5>
            {loading ? (
              <div className="loading-maxmin">
                <Loading type="spin" color="#0592F9" height={50} width={50} />
              </div>
            ) : (
              <div className="chart-maxmin">
                {Array.isArray(grafTemp) && grafTemp.length > 0 ? (
                  <>
                    <p>
                      <u>MAX:</u>
                      <strong style={{ fontSize: "1.2em" }}>
                        {" "}
                        {maxTnkData.tank}%{" "}
                      </strong>{" "}
                      el día {maxTnkData.dia}/{maxTnkData.mes} a las{" "}
                      {maxTnkData.hora}:{maxTnkData.minuto}{" "}
                    </p>
                    <p>
                      <u>MIN</u>
                      <strong style={{ fontSize: "1.2em" }}>
                        {" "}
                        {minTnkData.tank}%
                      </strong>
                      el día
                      {minTnkData.dia}/{minTnkData.mes} a las {minTnkData.hora}:
                      {minTnkData.minuto}
                    </p>
                    <p>
                      <u>Promedio:</u>
                      <strong style={{ fontSize: "1.2em" }}> {PromTnk}%</strong>
                    </p>
                  </>
                ) : (
                  <p>No se encontraron datos de Nivel de Tanque.</p>
                )}
              </div>
            )}
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <h4>Graficas</h4>
        </div>
        {/* GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  
        GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  GRAFICAS  
        **************************************************************************************************************/}
        <div className="itemgrafica">
          <h5>Temperatura Humedad y Luz</h5>
          {loading ? (
            <div className="loading-maxmin">
              <Loading type="spin" color="#0592F9" height={50} width={50} />
            </div>
          ) : (
            <div className="chart-maxmin" >
              {Array.isArray(grafTemp) && grafTemp.length > 0 ? (
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart data={grafTemp}>
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="dia" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                      dataKey="temp"
                      name="Temperatura"
                      stroke="#FEBE00"
                      strokeWidth={3}
                    />
                    <Line
                      dataKey="hum"
                      name="Humedad"
                      stroke="#5882FA"
                      strokeWidth={2}
                    />
                    <Line
                      dataKey="iluz"
                      name="Luz"
                      stroke="#FA5858"
                      strokeWidth={2}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No hay datos disponibles</p>
              )}
            </div>
          )}
        </div>
        <div className="itemgrafica">
          <h5>Temperatura</h5>
          {loading ? (
            <div className="loading-maxmin">
              <Loading type="spin" color="#0592F9" height={50} width={50} />
            </div>
          ) : (
            <div className="chart-maxmin">
              <ResponsiveContainer width="95%" height={250}>
                <LineChart data={grafTemp}>
                  <Line dataKey="temp" stroke="#FEBE00" strokeWidth={2} />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="dia" />
                  <YAxis domain={[0, 50]} />
                  <Tooltip content={<CustomTemp />} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
        {/* hasta aca */}
        <br />
        <div className="itemgrafica">
          <h5>Humedad</h5>

          {loading ? (
            <div className="loading-maxmin">
              <Loading type="spin" color="#0592F9" height={50} width={50} />
            </div>
          ) : (
            <div className="chart-maxmin">
              {Array.isArray(grafTemp) && grafTemp.length > 0 ? (
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart data={grafTemp}>
                    <Line dataKey="hum" stroke="#5882FA" strokeWidth={2} />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="dia" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip content={<CustomHumidity />} />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No hay datos disponibles</p>
              )}
            </div>
          )}
        </div>
        <br />
        <div className="itemgrafica">
          <h5>Temperatura Solución Nutriente (TSN)</h5>

          {loading ? (
            <div className="loading-maxmin">
              <Loading type="spin" color="#0592F9" height={50} width={50} />
            </div>
          ) : (
            <div className="chart-maxmin">
              {Array.isArray(grafTemp) && grafTemp.length > 0 ? (
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart data={grafTemp}>
                    <Line dataKey="temp" stroke="#9A2FFF" strokeWidth={2} />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="dia" />
                    <YAxis domain={[0, 50]} />
                    <Tooltip content={<CustomTSN />} />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No hay datos disponibles</p>
              )}
            </div>
          )}
        </div>
        <br />
        <div className="itemgrafica">
          <h5>Electroconductividad (EC)</h5>

          {loading ? (
            <div className="loading-maxmin">
              <Loading type="spin" color="#0592F9" height={50} width={50} />
            </div>
          ) : (
            <div className="chart-maxmin">
              {Array.isArray(grafTemp) && grafTemp.length > 0 ? (
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart data={grafTemp}>
                    <Line dataKey="ec" stroke="#2EFF65" strokeWidth={2} />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="dia" />
                    <YAxis tickCount={5} domain={[0, 7]} />
                    <Tooltip content={<CustomEC />} />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No hay datos disponibles</p>
              )}
            </div>
          )}
        </div>
        <br />
        <div className="itemgrafica">
          <h5>Tanque de Solución Nutriente</h5>

          {loading ? (
            <div className="loading-maxmin">
              <Loading type="spin" color="#0592F9" height={50} width={50} />
            </div>
          ) : (
            <div className="chart-maxmin">
              {Array.isArray(grafTemp) && grafTemp.length > 0 ? (
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart data={grafTemp}>
                    <Line dataKey="tank" stroke="#0000FF" strokeWidth={2} />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="dia" />
                    <YAxis tickCount={10} domain={[0, 100]} />
                    <Tooltip content={<CustomTank />} />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No hay datos disponibles</p>
              )}
            </div>
          )}
        </div>
        <br />
        <div className="itemgrafica">
          <h5>Intensidad de Luz</h5>

          {loading ? (
            <div className="loading-maxmin">
              <Loading type="spin" color="#0592F9" height={50} width={50} />
            </div>
          ) : (
            <div className="chart-maxmin">
              {Array.isArray(grafTemp) && grafTemp.length > 0 ? (
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart data={grafTemp}>
                    <Line dataKey="iluz" stroke="#FA5858" strokeWidth={2} />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="dia" />
                    <YAxis tickCount={10} domain={[0, 100]} />
                    <Tooltip content={<CustomLightIntensity />} />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No hay datos disponibles</p>
              )}
            </div>
          )}
        </div>

        <br />
        <div className="itemgrafica">
          <h5>Periodos de Irrigación</h5>

          {loading ? (
            <div className="loading-maxmin">
              <Loading type="spin" color="#0592F9" height={50} width={50} />
            </div>
          ) : (
            <div className="chart-maxmin">
              {Array.isArray(grafTemp) && grafTemp.length > 0 ? (
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart data={grafTemp}>
                    <Line dataKey="irriga" stroke="#0180FE" strokeWidth={2} />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="dia" />
                    <YAxis dataKey="irriga" domain={[0, 4]} />
                    <Tooltip content={<CustomIrrigationPeriods />} />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No hay datos disponibles</p>
              )}
            </div>
          )}
        </div>
        <br />
        <div className="itemgrafica">
          <h5>Periodos de Aireacion</h5>

          {loading ? (
            <div className="loading-maxmin">
              <Loading type="spin" color="#0592F9" height={50} width={50} />
            </div>
          ) : (
            <div className="chart-maxmin">
              {Array.isArray(grafTemp) && grafTemp.length > 0 ? (
                <ResponsiveContainer width="100%" height={150}>
                  <LineChart data={grafTemp}>
                    <Line dataKey="erelay2" stroke="#0180FE" strokeWidth={2} />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="dia" />
                    <YAxis dataKey="erelay2" domain={[0, 1]} />
                    <Tooltip content={<CustomO2 />} />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No hay datos disponibles</p>
              )}
            </div>
          )}
        </div>
        {/**/}
      </div>
      <div className="footVerde">
        <p>CARELHUE - Tecnologia en hidroponia </p>
      </div>
    </div>
  );
};

export default Graficos;
