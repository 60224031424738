import React, { useState, useEffect } from "react";

const EditForm = ({ data, table, onClose, onUpdate, isAdding }) => {
  const [editedData, setEditedData] = useState(isAdding ? {} : { ...data });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = () => {
    if (isAdding) {
      onUpdate(editedData); // Actualiza el estado en Admin
      onClose();
    } else {
      handleUpdate();
    }
  };

  const handleUpdate = () => {
    fetch(
      `https://www.carelhue.com.ar/php/admintabla${table}.php?action=edit${table}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedData),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          onUpdate(editedData); // Actualizar estado en Admin
          onClose();
        }
      })
      .catch((error) => console.error("Error updating data:", error));
  };

  useEffect(() => {
    // Actualiza el editedData cuando cambia la data
    setEditedData(isAdding ? {} : { ...data });
  }, [data, isAdding]);

  return (
    <div>
      <div>
        <h3>{isAdding ? "Agregar Registro" : "Editar Registro"}</h3>
        <form>
          {Object.keys(editedData).map((key) => (
            <div key={key}>
              <label>{key}</label>
              <input
                type="text"
                name={key}
                value={editedData[key] || ""}
                onChange={handleInputChange}
              />
            </div>
          ))}
        </form>
      </div>
      <div>
        <button onClick={handleSave}>
          {isAdding ? "Agregar" : "Guardar Cambios??? Seguro??"}
        </button>
        <button onClick={onClose}>Cancelar</button>
      </div>
    </div>
  );
};

export default EditForm;
