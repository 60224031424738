import React, { useState } from "react";
import "./usuario.css";
import EditForm from "../usuarios2/cruds/EditFormUser";
import emailjs from "emailjs-com";

const Usuario = () => {
  const userName = localStorage.getItem("userName");
  const macData = localStorage.getItem("macData");

  const [data, setData] = useState([]);
  const [selectedTable, setSelectedTable] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  const [isSent, setIsSent] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });

  const fetchData = (table) => {
    console.log(table);
    console.log(userName);
    fetch(
      `https://www.carelhue.com.ar/php/userchangeconfig.php?action=fetch${table}&userName=${userName}`
    )
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleTableChange = (tableName) => {
    setSelectedTable(tableName);
    fetchData(tableName);
  };

  const handleUpdate = (updatedData) => {
    // Actualizar los datos en el estado
    const updatedArray = data.map((item) =>
      item.Id_usuario === updatedData.Id_usuario ? updatedData : item
    );
    setData(updatedArray);
    setIsEditing(false);
    setEditData(null);
  };

  const handleSave = (newData) => {
    fetch(
      `https://www.carelhue.com.ar/php/userchangeconfig.php?action=editusuarios`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          fetchData(selectedTable); // Actualizar datos después de agregar
          setIsEditing(false);
          setIsAdding(false);
        }
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  const renderTableRows = () => {
    // Render table rows based on data and selected table
    return data.map((row, index) => (
      <tr key={index}>
      {/* Map only specific data properties to table cells based on selected table */}
      {/* <td>{row.Id_usuario}</td> */}
      <td>{row.usuario}</td>
      <td>{row.password}</td>
      <td>{row.email}</td>
      <td>{row.mac}</td>
      <td>{row.fecha}</td>
      <td style={{textAlign:"center"}}>
        {isAdding ? (
          <button onClick={() => handleSave(row)}>Guardar</button>
        ) : (
          <>
            <button onClick={() => handleEdit(row)}>Editar</button>
                     
            <button style={{marginLeft:"20px"}} onClick={() => handleMail()}>Enviar Mail</button>
            {isSent && (
                    <h2 style={{ color: "black" }}>
                      ¡Mensaje enviado correctamente!
                    </h2>
                  )}
            </>

        )}
      </td>
    </tr>
    ));
  };

  const handleEdit = (data) => {
    setIsEditing(true);
    setEditData(data);
  };

  const handleMail  = (event) => {
    event.preventDefault();
    emailjs
      .send(
        "gmail_info_carelhue",
        "template_carelhue",
        formValues,
        "WXX8wn4yRQLjxVeyH"
      )
      .then(() => {
        setIsSent(true);
        resetForm();
        setFormValues({
          name: "",
          email: "",
          message: "",
          reply_To: "piloto.chevrolet@gmail.com",
        });
      })
      .catch((error) => console.log(error));
  };
  const resetForm = () => {
    setFormValues({
      name: "",
      email: "",
      message: "",
    });
  };

  const renderTableHeaders = () => {
    // Define table headers based on the selected table      style={{backgroundColor:"#007bff", color:"white", textAlign: "center"}}
    switch (selectedTable) {
      case "usuarios":
        return (
          <tr>
            {/* <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Id_usuario
            </th> */}
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Usuario
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Password
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Mail
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              MAC
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Fecha
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Opciones
            </th>
            {/* Adcd more header columns */}
          </tr>
        );
      default:
        return null;
    }
  };

  return (
    <div className="contenedorUsuario">
      <div className="DashtituloUsuario">
        <div>
          <div className="divUsuario">
            <h4>
              USUARIO
              <i className="fas fa-user" style={{ paddingLeft: "20px", color:"#839192"}}></i>
            </h4>
          </div>
        </div>
        <div>
          <h5>MAC: {macData}  Usuario: {userName}</h5>
        </div>
        <div></div>

        <div>
          <div>
            <div className="section-buttons">
              <button
                className="section-button"
                onClick={() => handleTableChange("usuarios")}
              >
                <i className="fas fa-user"></i> Usuario
              </button>
            </div>
            <br />
            <div>
              <table>
                <thead>{renderTableHeaders()}</thead>
                <tbody>{renderTableRows()}</tbody>
              </table>
            </div>
            {isEditing && (
              <EditForm
                data={editData}
                table={selectedTable}
                onClose={() => setIsEditing(false)}
                onUpdate={handleUpdate} // Define this function to handle updates
                isAdding={isAdding}
              />
            )}
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="footVerde">
          <p>CARELHUE - Tecnologia en hidroponia </p>
        </div>
      </div>
    </div>
  );
};

export default Usuario;
