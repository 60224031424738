import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import "./blog.css";

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch("https://www.carelhue.com.ar/carelhue/blog/blog.php") 
      .then((response) => {
        if (!response.ok) {
          throw new Error("La solicitud no pudo completarse correctamente.");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setPosts(data);
      })
      .catch((error) => {
        console.error("Error al cargar los posts", error);
      });
  }, []);

  return (
    <div className="blog-container">
      <div className="content">
        <h1>
          Blog{" "}
          <i
            className="fas fa-Blog"
            style={{ paddingLeft: "20px", color: "#CF9951" }}
          ></i>{" "}
        </h1>
        <div className="post-grid">
          {posts.map((post) => (
            <div className="post-card" key={post.idblog}>
              <div
                className="post-image"
                style={{ backgroundImage: `url(${post.imagen})` }}
              ></div>
              <Link to={`/blog/${post.idblog}`} className="post-title-link">
                <h2 className="post-title-link">{post.titulo}</h2>
              </Link>
              <p className="post-content" style={{ textAlign: "left" }}>
                {post.subtitulo}
              </p>
              <p className="post-content" style={{ textAlign: "right" }}>
                {format(new Date(post.fechayhora), "MMM-yy")}
              </p>
            </div>
          ))}
        </div>
      </div>
      <footer>
        <p>&copy; 2023 CARELHUE - Tecnología en Hidroponía</p>
      </footer>
    </div>
  );
};

export default Blog;
