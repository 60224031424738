import React,{useState} from "react";

export const Descripcion = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const openModal = (src) => {
    setImageSrc(src);
    setShowModal(true);
  };

  const closeModal = () => {
    setImageSrc("");
    setShowModal(false);
  };
  return (
    <div id="descripcion" className="text-center">
      <div className="descripcionback">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="section-title">
          <h2>Descripcion</h2>
        </div>
        <div className="descripcioncontainer">
          <div className="row">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-md-12"
                    style={{
                      margin: "8px 10px 7px",
                      height: "relative",
                      borderRadius: "18px 18px 18px",
                      background: "#abffc0",
                    }}
                  >
                    {" "}
                    <br></br>
                    {/* <i className={d.icon}></i> */}
                    <h3>{d.title}</h3>
                    <br />
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td style={{ alignItems: "center" }}>
                            <video controls width="640" height="360">
                              <source
                                src="http://carelhue.com.ar/carelhue/videos/PresentacionCarelhue.mp4"
                                type="video/mp4"
                              />
                              Tu navegador no admite la reproducción de videos.
                            </video>
                          </td>
                          <td style={{ alignItems: "center" }}>
                          <img
          src={d.img}
          className="img-responsive"
          alt=""
          width="230px"
          height="230px"
          style={{ borderRadius: "8px 8px 8px 8px" }}
          onClick={() => openModal(d.img)}
        />
        {showModal && (
          <div className="modal">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img src={imageSrc} alt="" />
          </div>
        )}
                            {/* <img 
                              src={d.img}
                              className="img-responsive"
                              alt=""
                              width="200px"
                              height="200px"
                              style={{ borderRadius: "18px 18px 18px 18px" }}
                            />{" "} */}
                          </td>
                          <td style={{ alignItems: "center" }}>
                          <img
          src={d.img1}
          className="img-responsive"
          alt=""
          width="230px"
          height="230px"
          style={{ borderRadius: "8px 8px 8px 8px" }}
          onClick={() => openModal(d.img1)}
        />
        {showModal && (
          <div className="modal">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img src={imageSrc} alt="" />
          </div>
        )}
                            {/* <img
                              src={d.img1}
                              className="img-responsive"
                              alt=""
                              width="200px"
                              height="200px"
                              style={{ borderRadius: "18px 18px 18px 18px" }}
                            />{" "} */}
                          </td>
                          <td style={{ alignItems: "center" }}>
                          <img
          src={d.img2}
          className="img-responsive"
          alt=""
          width="230px"
          height="230px"
          style={{ borderRadius: "8px 8px 8px 8px" }}
          onClick={() => openModal(d.img2)}
        />
        {showModal && (
          <div className="modal">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img src={imageSrc} alt="" />
          </div>
        )}
                            {/* <img
                              src={d.img2}
                              className="img-responsive"
                              alt=""
                              width="200px"
                              height="200px"
                              style={{ borderRadius: "18px 18px 18px 18px" }}
                            />{" "} */}
                          </td>
                          <td style={{ alignItems: "center" }}>
                          <img
          src={d.img3}
          className="img-responsive"
          alt=""
          width="230px"
          height="230px"
          style={{ borderRadius: "8px 8px 8px 8px" }}
          onClick={() => openModal(d.img3)}
        />
        {showModal && (
          <div className="modal">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img src={imageSrc} alt="" />
          </div>
        )}
                            {/* <img
                              src={d.img3}
                              className="img-responsive"
                              alt=""
                              width="200px"
                              height="200px"
                              style={{ borderRadius: "18px 18px 18px 18px" }}
                            />{" "} */}
                          </td>
                          <td style={{ alignItems: "center" }}>
                          <img
          src={d.img4}
          className="img-responsive"
          alt=""
          width="230px"
          height="230px"
          style={{ borderRadius: "8px 8px 8px 8px" }}
          onClick={() => openModal(d.img4)}
        />
        {showModal && (
          <div className="modal">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img src={imageSrc} alt="" />
          </div>
        )}
                            {/* <img
                              src={d.img4}
                              className="img-responsive"
                              alt=""
                              width="200px"
                              height="200px"
                              style={{ borderRadius: "18px 18px 18px 18px" }}
                            />{" "} */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p
                      style={{
                        textAlign: "justify",
                        marginLeft: "2%",
                        marginRight: "2%",
                      }}
                    >
                      {props.data && d.text ? (
                        <div dangerouslySetInnerHTML={{ __html: d.text }} />
                      ) : (
                        "Cargando..."
                      )}
                    </p>
                    <p
                      style={{
                        textAlign: "justify",
                        marginLeft: "2%",
                        marginRight: "2%",
                      }}
                    >
                      {props.data && d.text1 ? (
                        <div dangerouslySetInnerHTML={{ __html: d.text1 }} />
                      ) : (
                        "Cargando..."
                      )}
                    </p>
                    <p
                      style={{
                        textAlign: "justify",
                        marginLeft: "2%",
                        marginRight: "2%",
                      }}
                    >
                      {props.data && d.text2 ? (
                        <div dangerouslySetInnerHTML={{ __html: d.text2 }} />
                      ) : (
                        "Cargando..."
                      )}
                    </p>
                    <p
                      style={{
                        textAlign: "justify",
                        marginLeft: "2%",
                        marginRight: "2%",
                      }}
                    >
                      {props.data && d.text3 ? (
                        <div dangerouslySetInnerHTML={{ __html: d.text3 }} />
                      ) : (
                        "Cargando..."
                      )}
                    </p>
                    <p
                      style={{
                        textAlign: "justify",
                        marginLeft: "2%",
                        marginRight: "2%",
                      }}
                    >
                      {props.data && d.text4 ? (
                        <div dangerouslySetInnerHTML={{ __html: d.text4 }} />
                      ) : (
                        "Cargando..."
                      )}
                    </p>
                    <p
                      style={{
                        textAlign: "justify",
                        marginLeft: "2%",
                        marginRight: "2%",
                      }}
                    >
                      {props.data && d.text5 ? (
                        <div dangerouslySetInnerHTML={{ __html: d.text5 }} />
                      ) : (
                        "Cargando..."
                      )}
                    </p>
                    <br />
                    {/* Agregar el botón de descarga */}
                    <button
                      onClick={() =>
                        window.open(
                          "http://www.carelhue.com.ar/descargas/CarelHue.pdf",
                          "_blank"
                        )
                      }
                      className="btn btn-primary"
                    >
                      Descargar Manual del Usuario
                    </button>
                    <br />
                    {/* ... */}
                    <br />
                  </div>
                ))
              : "Cargando..."}
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};
