import React, { useState } from "react";
import { Helmet } from "react-helmet";

export const Team = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const openModal = (src) => {
    setImageSrc(src);
    setShowModal(true);
  };

  const closeModal = () => {
    setImageSrc("");
    setShowModal(false);
  };
  return (
    <div id="team" className="teamback" style={{ paddingBottom: "5px" }}>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Person",
              "name": "Ariel Aranda",
              "url": "http://carelhue.com.ar/",
              "image": "https://carelhue.com.ar/img/team/ceo_carelhue.png",
              "sameAs": [
                "https://www.linkedin.com/in/ariel-aranda-3ba199253/"
              ]
            }
          `}
        </script>
      </Helmet>
      <div className="section-title">
        <h2 style={{ textAlign: "center" }}>Perfil del Autor</h2>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div>
              <div>
                {props.data
                  ? props.data.map((d, i) => (
                      <div
                        key={`${d.name}-${i}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          paddingBottom: "50px",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          {" "}
                          <img
                            src={d.img}
                            className="img-responsive"
                            alt="Ariel Aranda - Proyect Manager"
                            width="230px"
                            height="260px"
                            style={{ borderRadius: "8px 8px 8px 8px" }}
                            onClick={() => openModal(d.img)}
                          />
                          {showModal && (
                            <div className="modal">
                              <span className="close" onClick={closeModal}>
                                &times;
                              </span>
                              <img src={imageSrc} alt="Ariel Aranda - Proyect Manager" />
                            </div>
                          )}

                          <h4 style={{ textAlign: "center" }}>{d.name}</h4>
                          <p style={{ textAlign: "center", color: "black" }}>
                            {d.job}
                          </p>
                        </div>
                        <div
                          style={{ flex: 2, width: "90%", margin: "20px auto" }}
                        >
                          <p style={{ textAlign: "justify", color: "black" }}>
                            {props.data && d.text ? (
                              <div
                                dangerouslySetInnerHTML={{ __html: d.text }}
                              />
                            ) : (
                              "Cargando..."
                            )}
                          </p>
                          <p style={{ textAlign: "justify", color: "black" }}>
                            {props.data && d.text1 ? (
                              <div
                                dangerouslySetInnerHTML={{ __html: d.text1 }}
                              />
                            ) : (
                              "Cargando..."
                            )}
                          </p>
                          <p style={{ textAlign: "justify", color: "black" }}>
                            {props.data && d.text2 ? (
                              <div
                                dangerouslySetInnerHTML={{ __html: d.text2 }}
                              />
                            ) : (
                              "Cargando..."
                            )}
                          </p>
                        </div>
                      </div>
                    ))
                  : "Cargando"}
              </div>

              <p />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;

