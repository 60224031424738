import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate(); // Agregamos el hook useHistory
  const [correo, setCorreo] = useState("");
  const [contrasenia, setContraseña] = useState("");
  const [mensaje, setMensaje] = useState("");


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('http://localhost:3006/logAdm', {
        method: 'POST',
        mode:"cors",
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({ correo, contrasenia })
      });
      console.log("RESPONSE." + response);
      if (response.ok) {
        setMensaje("Inicio de sesión exitoso");
        // Aquí podrías redirigir al usuario a otra página
        const session = { correo };
        localStorage.setItem('session', JSON.stringify(session));    
        window.location.href = "http://localhost:3000/AdminDashboard";
        //window.location.href = "http://www.clarin.com";
        
      } else {
        setMensaje("Correo o contraseña incorrectos!");
      }
    } catch (error) {
      console.error(error);
      setMensaje("Error en el servidor:" + error);
    }
  };

  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1>Página de Login Adminstracion</h1>
      <h1>/adminstracion/Login.js</h1>
      <br />
      <br />

      <button onClick={() => navigate("/Campus")}>Volver</button>{" "}
      {/* Botón para volver */}
      <div>
        <h1>Iniciar sesión</h1>
        <p>credentials:admin@admin.com/admin</p>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="correo">Correo electrónico:</label>
            <input
              type="email"
              id="correo"
              value={correo}
              onChange={(event) => setCorreo(event.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="contraseña">Contraseña:</label>
            <input
              type="password"
              id="contrasenia"
              value={contrasenia}
              onChange={(event) => setContraseña(event.target.value)}
              required
            />
          </div>
          <button type="submit">Iniciar Administracion</button>
        </form>
        {mensaje && <p>{mensaje}</p>}
      </div>
    </div>
  );
};

export default Login;
