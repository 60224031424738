import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const navigate = useNavigate(); // Agregamos el hook useHistory
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [mensaje, setMensaje] = useState("");

  // const [session, setSession] = useState(null);
  // useEffect(() => {
  //   const storedSession = localStorage.getItem('session');
  //   // Verificar si hay un objeto de sesión o token almacenado en el almacenamiento local del navegador
  //   if (storedSession) {
  //     setSession(JSON.parse(storedSession));
  //   } else {
  //     window.location.href = 'http://localhost:3000/Campus';
  //   }
  // }, []);
//Trae los registros de la tabla users
  const [registros, setRegistros] = useState([]);
    useEffect(() => {
      async function fetchRegistros() {
        const respuesta = await fetch('http://localhost:3006/getUsers');
        const datos = await respuesta.json();
        setRegistros(datos);
      }
      fetchRegistros();
    }, []);

//elimina el registro de la tabla user
  const eliminarRegistro = (iduser) => {
    fetch(`http://localhost:3006/delUsers:${iduser}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al eliminar el registro');
        }
        Error('Se ELIMINO el registro');
        // Si la eliminación fue exitosa, actualizamos el estado
        setRegistros(registros.filter(registro => registro.iduser !== iduser));
      })
      .catch(error => {
        console.error(error);
      });
  };

//modifica el registro de la tabla users
//const [registros, setRegistros] = useState([]);
  const [registroActual, setRegistroActual] = useState(null);
  const [modalAbierto, setModalAbierto] = useState(false);
  const [nombreEditado, setNombreEditado] = useState('');
  const [apellidoEditada, setApellidoEditada] = useState('');

  async function modificarRegistro(iduser) {
    // Buscar el registro en la lista de registros
    const registro = registros.find(registro => registro.iduser === iduser);
    // Guardar el registro actual en el estado
    setRegistroActual(registro);
    // Abrir el modal
    setModalAbierto(true);
    // Guardar los valores de los campos editables
    setNombreEditado(registro.nombre);
    setApellidoEditada(registro.apellido);
  }

  function guardarCambios() {
    // Actualizar el registro en la lista de registros
    const nuevosRegistros = registros.map(registro => {
      if (registro.iduser === registroActual.iduser) {
        return { ...registro, nombre: nombreEditado, descripcion: apellidoEditada };
      } else {
        return registro;
      }     
    });
    setRegistros(nuevosRegistros);
    // Cerrar el modal
    setModalAbierto(false);
    fetch(`http://localhost:3006/modificar:${registroActual.iduser}`, {
      method: 'PUT',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al modificar el registro');
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  function cancelarCambios() {
    // Cerrar el modal
    setModalAbierto(false);
  }







  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("http://localhost:3006/Cursos", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ nombre, apellido, mail, password }),
      });
      //const data = await response.json();
      //console.log("DATA." + data);
      //eturn data;
      console.log("RESPONSE." + response);
      if (response.ok) {
        setMensaje("Inscripción exitosa");
        // Aquí podrías redirigir al usuario a otra página
      } else {
        setMensaje("Error en la inscripción");
      }
    } catch (error) {
      console.error(error);
      setMensaje("Error en el servidor");
    }
  };

  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1>Dashboard de Administracion</h1>
      <h1>/administracion/AdminDashboard.js</h1>
      <br />
      <br />
      <button onClick={() => navigate("/Campus")}>Salir</button>{" "}
      {/* Botón para volver */}
      <br />
      <div>
        <h1>DASHBOARD DE ADMINISTRACION</h1>

        <br />
        <br />
        <div>
          <p />
          <h3>Registros de usuarios</h3>
          <div>
            <p>Tabla Users</p>
            <p>----------------------------</p>

            <table>
              <tr  width={'700px'}>
              <td width={'50px'}>iduser</td>
              <td width={'50px'}>Nombre</td>
              <td width={'50px'}>Apellido</td>
              <td width={'200px'}>Mail</td>
              <td width={'50px'}>Password</td>
              <td width={'50px'}>Rol</td>
              <td width={'50px'}>Fecha</td>
              <td></td>
              <td></td>
              </tr>
            </table>
            <table>  
            {registros.map((registro) => (
            <tr width={'700px'}>
              <td>
                <div key={registro.iduser}>
                <td width={'50px'}><p>{registro.iduser}</p></td>
                <td width={'50px'}><p>{registro.nombre}</p></td>
                <td width={'50px'}><p> {registro.apellido}</p></td>
                <td width={'200px'}><p>{registro.correo}</p></td>
                <td width={'50px'}><p>{registro.password}</p></td>
                <td width={'50px'}><p>{registro.rol}</p></td>
                <td width={'50px'}><p>{registro.fecha}</p></td>
                <td width={'50px'}><button onClick={() => eliminarRegistro(registro.iduser)}>
                  Eliminar
                </button></td>
                <td width={'50px'}><button onClick={() => modificarRegistro(registro.iduser)}>
                  Modificar
                </button></td>
                </div>
              </td>
              </tr>
            ))}
            </table>
            {modalAbierto && (
        <div>
          <h2>Editar Registro</h2>
          <p>ID: {registroActual.iduser}</p>
          <label htmlFor="nombre">Nombre:</label>
          <input type="text" id="nombre" value={nombreEditado} onChange={event => setNombreEditado(event.target.value)} />
          <label htmlFor="apellido">Apellido:</label>
          <textarea id="apellido" value={apellidoEditada} onChange={event => setApellidoEditada(event.target.value)} />
          <button onClick={guardarCambios}>Guardar</button>
          <button onClick={cancelarCambios}>Cancelar</button>
        </div>
      )}
          </div>
          <p>--------------END------------------</p>
        </div>
        <br/><br/><br/><br/><br/>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="nombre">Nombre:</label>
            <input
              type="text"
              id="nombre"
              value={nombre}
              onChange={(event) => setNombre(event.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="apellido">Apellido:</label>
            <input
              type="text"
              id="apellido"
              value={apellido}
              onChange={(event) => setApellido(event.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="mail">Correo electrónico:</label>
            <input
              type="email"
              id="mail"
              value={mail}
              onChange={(event) => setMail(event.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Contraseña:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
            />
          </div>
          <button type="submit">Pagina del alumno</button>
        </form>
        {mensaje && <p>{mensaje}</p>}
      </div>
    </div>
  );
};

export default AdminDashboard;
