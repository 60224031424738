import React, { useState, useEffect } from "react";
import EditForm from "./cruds/EditForm";
import "./admin.css";

import { useNavigate } from "react-router-dom";

const Admin = () => {
  const [data, setData] = useState([]);

  const [selectedTable, setSelectedTable] = useState("usuarios");
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isAddingRow, setIsAddingRow] = useState(false); // Variable para mostrar botones en filas

  const fetchData = (table) => {
    fetch(
      `https://www.carelhue.com.ar/php/admintabla${table}.php?action=fetch${table}`
    )
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleTableChange = (tableName) => {
    setSelectedTable(tableName);
    fetchData(tableName);
  };

  useEffect(() => {
    fetchData(selectedTable);
  }, [selectedTable]);

  const handleSave = (newData) => {
    fetch(
      `https://www.carelhue.com.ar/php/admintabla${selectedTable}.php?action=add${selectedTable}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          fetchData(selectedTable); // Actualizar datos después de agregar
          setIsEditing(false);
          setIsAdding(false);
        }
      })
      .catch((error) => console.error("Error adding data:", error));
  };

  const renderTableHeaders = () => {
    // Define table headers based on the selected table      style={{backgroundColor:"#007bff", color:"white", textAlign: "center"}}
    switch (selectedTable) {
      case "usuarios":
        return (
          <tr>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Id_usuario
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Usuario
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Password
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Rol
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Mail
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              MAC
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Fecha
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Opciones
            </th>
            {/* Adcd more header columns */}
          </tr>
        );
      case "vivero":
        return (
          <tr>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              Id_vivero
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              Nombre
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              Tipo
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              Germina
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              EC Germina
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              Crece
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              EC Crece
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              Poliniza
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              EC Poliniza
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              Fruto
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              EC Fruto
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              Estacion
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              Observacion1
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              Observacion2
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              Vitaminas
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              Ver
            </th>
            <th
              style={{
                backgroundColor: "#33cc8a",
                color: "white",
                textAlign: "center",
              }}
            >
              Opciones
            </th>
            {/* Add more header columns */}
          </tr>
        );
      case "consultas":
        return (
          <tr>
            <th
              style={{
                backgroundColor: "#d29d00",
                color: "white",
                textAlign: "center",
              }}
            >
              Id_consulta
            </th>
            <th
              style={{
                backgroundColor: "#d29d00",
                color: "white",
                textAlign: "center",
              }}
            >
              Mail
            </th>
            <th
              style={{
                backgroundColor: "#d29d00",
                color: "white",
                textAlign: "center",
              }}
            >
              Topico
            </th>
            <th
              style={{
                backgroundColor: "#d29d00",
                color: "white",
                textAlign: "center",
              }}
            >
              Consulta
            </th>
            <th
              style={{
                backgroundColor: "#d29d00",
                color: "white",
                textAlign: "center",
              }}
            >
              Respuesta
            </th>
            <th
              style={{
                backgroundColor: "#d29d00",
                color: "white",
                textAlign: "center",
              }}
            >
              Fecha
            </th>
            <th
              style={{
                backgroundColor: "#d29d00",
                color: "white",
                textAlign: "center",
              }}
            >
              Opciones
            </th>
            {/* Add more header columns */}
          </tr>
        );
      case "descargas":
        return (
          <tr>
            <th
              style={{
                backgroundColor: "#6c757d",
                color: "white",
                textAlign: "center",
              }}
            >
              Id_descargas
            </th>
            <th
              style={{
                backgroundColor: "#6c757d",
                color: "white",
                textAlign: "center",
              }}
            >
              URL
            </th>
            <th
              style={{
                backgroundColor: "#6c757d",
                color: "white",
                textAlign: "center",
              }}
            >
              Nombre
            </th>
            <th
              style={{
                backgroundColor: "#6c757d",
                color: "white",
                textAlign: "center",
              }}
            >
              Tipo
            </th>
            <th
              style={{
                backgroundColor: "#6c757d",
                color: "white",
                textAlign: "center",
              }}
            >
              Descripcion
            </th>
            <th
              style={{
                backgroundColor: "#6c757d",
                color: "white",
                textAlign: "center",
              }}
            >
              Fecha
            </th>
            <th
              style={{
                backgroundColor: "#6c757d",
                color: "white",
                textAlign: "center",
              }}
            >
              Opciones
            </th>
            {/* Add more header columns */}
          </tr>
        );
      case "registros":
        return (
          <tr>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              id
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              mac
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              ip
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              dia
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              mes
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              anio
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              hora
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              minuto
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              au
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              accion
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              iluz
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              cluz
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              temp
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              hum
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              temp_sn
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              ppm
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              ec
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              Set_ec
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              erelay1
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              erelay2
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              erelay3
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              irriga
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              tank
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              email
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              fechayhora
            </th>
            {/* Add more header columns */}
          </tr>
        );
      case "cursos":
        return (
          <tr>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              id_curso
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              curso
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              imagen
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              descripcion
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              inicio
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              dura
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              link
            </th>
            <th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              fechayhora
            </th><th
              style={{
                backgroundColor: "#3b56ba",
                color: "white",
                textAlign: "center",
              }}
            >
              Opciones
            </th>
            {/* Add more header columns */}
          </tr>
        );
      case "blog":
        return (
          <tr>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              idblog
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              imagen
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              titulo
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              subtitulo
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              post
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              post1
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              enlace
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              fechayhora
            </th><th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              Opciones
            </th>
            
            {/* Add more header columns */}
          </tr>
        );
      case "comentarios":
        return (
          <tr>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              idcomentarios
            </th> <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              blogid
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              nombre
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              comentario
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              fechayhora
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              Opciones
            </th>
            {/* Add more header columns */}
          </tr>
        );
      default:
        return null;
    }
  };

  const renderTableRows = () => {
    // Render table rows based on data and selected table
    return data.map((row, index) => (
      <tr key={index}>
        {/* Map data properties to table cells based on selected table */}
        {Object.keys(row).map((key) => (
          <td
            key={key}
            style={{
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {row[key]}
          </td>
        ))}
        <td>
          {isAddingRow ? (
            <button onClick={() => handleSave(row)}>Guardar</button>
          ) : (
            <>
              <button onClick={() => handleEdit(row)}>Editar</button>
              <button onClick={() => handleDelete(row)}>Eliminar</button>
              <button onClick={() => handleAdd()}>Agregar</button>
            </>
          )}
        </td>
      </tr>
    ));
  };

  const handleAdd = () => {
    setIsEditing(true);
    setIsAddingRow(true); // Mostrar botones en las filas
    setIsAdding(true); // Indicar que se agrega un nuevo registro en el formulario
    setEditData({}); // Reinicia los datos del formulario
  };
 
  const handleEdit = (data) => {
    setIsEditing(true);
    setEditData(data);
  };

  const handleUpdate = (updatedData) => {
    // Actualizar los datos en el estado
    const updatedArray = data.map((item) =>
      item.Id_usuario === updatedData.Id_usuario ? updatedData : item
    );
    setData(updatedArray);
    setIsEditing(false);
    setEditData(null);
  };

  const handleDelete = (data) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este registro?"
    );
    if (confirmed) {
      fetch(
        `https://www.carelhue.com.ar/php/admintabla${selectedTable}.php?action=delete${selectedTable}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id:
              data.Id_usuario ||
              data.Id_vivero ||
              data.Id_consulta ||
              data.Id_descargas ||
              data.idcomentario ||
              data.id,
          }),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            const updatedArray = data.filter(
              (item) =>
                item.Id_usuario !== data.Id_usuario ||
                item.Id_vivero !== data.Id_vivero ||
                item.Id_consulta !== data.Id_consulta ||
                item.Id_descargas !== data.Id_descargas ||
                item.idcomentario !== data.idcomentario ||
                item.id !== data.id
            );
            setData(updatedArray);
          }
        })
        .catch((error) => console.error("Error deleting data:", error));
    }
  };

  let navigate = useNavigate();
  function Salir() {
    console.log("dentro del handle go()");
    //historyObj.push("../AdminDash");
    navigate("../Usuarios2");
  }

  return (
    <div>
      <br />
      <div class="container">
        <div class="left-div">
          <div className="section-title">
            <h2 style={{ textAlign: "center" }}>
              <i className="fas fa-cogs" style={{ fontSize: "34px" }}></i>
              DASHBOARD ADMINISTRACION
            </h2>
          </div>
        </div>
        <div class="right-div">
          <button class="exit-button" onClick={Salir}>
            Salir
            <i class="fas fa-sign-out-alt"></i>
          </button>
        </div>
      </div>
      <div className="section-buttons">
        <button
          className="section-button"
          onClick={() => handleTableChange("usuarios")}
        >
          <i className="fas fa-users"></i> Usuarios
        </button>
        <button
          className="section-button"
          onClick={() => handleTableChange("vivero")}
        >
          <i className="fas fa-leaf"></i> Vivero
        </button>
        <button
          className="section-button"
          onClick={() => handleTableChange("consultas")}
        >
          <i className="fas fa-search"></i> Consultas
        </button>
        <button
          className="section-button"
          onClick={() => handleTableChange("descargas")}
        >
          <i className="fas fa-download"></i> Descargas
        </button>
        <button
          className="section-button"
          onClick={() => handleTableChange("registros")}
        >
          <i className="fas fa-file-alt"></i> Registros
        </button>
        <button
          className="section-button"
          onClick={() => handleTableChange("cursos")}
        >
          <i className="fas fa-graduation-cap"></i> Cursos
        </button>
        <button
          className="section-button"
          onClick={() => handleTableChange("blog")}
        >
          <i className="fas fa-blog"></i> Blog
        </button>
        <button
          className="section-button"
          onClick={() => handleTableChange("comentarios")}
        >
          <i className="fas fa-comment"></i> Comentarios
        </button>
        <div></div>
      </div>

      <br />
      <table>
        <thead>{renderTableHeaders()}</thead>
        <tbody>{renderTableRows()}</tbody>
      </table>
      {isEditing && (
        <EditForm
          data={editData}
          table={selectedTable}
          onClose={() => {
            setIsEditing(false);
            setIsAdding(false); // Asegurarse de que isAdding se restablezca
          }}
          onUpdate={isAdding ? handleSave : handleUpdate}
          isAdding={isAdding}
        />
      )}
      <br/><br/><br/><br/><br/>
    </div>
  );
};

export default Admin;
