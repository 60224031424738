import React from "react";
import { useState, useEffect } from "react";

import Usr from "../dashboards/dashboard";

import JsonData from "../../data/data.json";

import "./usuarios.css";

const UserDash = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div className="fondodash" style={{ overflowY: "none" }}>
      <div className="centrado">
        <br />
        <br />
        <br />

        <div className="centro">
          <Usr data={landingPageData.Usr} />
        </div>
      </div>
    </div>
  );
};
export default UserDash;
