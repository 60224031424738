import React, { useEffect, useState, useCallback } from "react";
import Loading from "react-loading";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlugCircleCheck,
  faPlugCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import "./inicio.css";

const Inicio = () => {
  const [loading, setLoading] = useState(true);
  const userName = localStorage.getItem("userName");
  const macData = localStorage.getItem("macData");
  const desde = localStorage.getItem("fechaDesde");
  const hasta = localStorage.getItem("fechaHasta");
  //const [LastReg, setLastReg] = useState([]);
  const [ultimoRegistro, setUltimoRegistro] = useState(null);
  //const [anteultimoRegistro, setAnteUltimoRegistro] = useState(null);
  const [arrowImagetemp, setArrowImageTemperatura] = useState(null);
  const [arrowImageHum, setArrowImageHum] = useState(null);
  const [arrowImageTank, setArrowImageTank] = useState(null);
  const [arrowImageTempSN, setArrowImageTempSN] = useState(null);
  const [arrowImageEc, setArrowImageEc] = useState(null);
  const [arrowImageLuz, setArrowImageLuz] = useState(null);
  const [ultimaSemana, setUltimaSemana] = useState([]);

  const Ultimo = useCallback(() => {
    setLoading(true);

    console.log("INICIO: usuario" + userName);
    console.log("INICIO: MAC" + macData);
    console.log("INICIO: desde" + desde);
    console.log("INICIO: hasta" + hasta);

    //fetch("http://localhost/carelhue/php/graficas.php", {
    fetch("https://www.carelhue.com.ar/php/graficas.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ hwid: macData, desd: desde, hast: hasta }),
    })
      .then((res) => res.json())
      .then((data) => {
        const res = data;
        //setLastReg(res);
        console.log(res);
        if (res.length > 0) {
          const ultimoRegistro = res[res.length - 1];
          const anteultimoRegistro = res[res.length - 4];
          //const ultimaSemana=res;
          const ultimos24Registros = res.slice(res.length - 10);
          setUltimoRegistro(ultimoRegistro);
          setUltimaSemana(ultimos24Registros);
          console.log("ultimaSemana:");
          console.log(ultimos24Registros);
          //setAnteUltimoRegistro(anteultimoRegistro);
          const diferenciaTemperatura =
            ultimoRegistro.temp - anteultimoRegistro.temp;
          const diferenciaHumedad = ultimoRegistro.hum - anteultimoRegistro.hum;
          const diferenciaEC = ultimoRegistro.ec - anteultimoRegistro.ec;
          const diferenciaTempSN =
            ultimoRegistro.temp_sn - anteultimoRegistro.temp_sn;
          const diferenciaTank = ultimoRegistro.tank - anteultimoRegistro.tank;
          const diferenciaLuz = ultimoRegistro.iluz - anteultimoRegistro.iluz;

          if (diferenciaTemperatura === 0) {
            setArrowImageTemperatura(
              <img
                src={"https://carelhue.com.ar/img/dashboard/equal_arrow.png"}
                alt="Equal Arrow"
                className="arrow-image"
              />
            );
          } else if (diferenciaTemperatura > 0) {
            setArrowImageTemperatura(
              <img
                src={"https://carelhue.com.ar/img/dashboard/uparrow.png"}
                alt="Up Arrow"
                className="arrow-image"
              />
            );
          } else if (diferenciaTemperatura < 0) {
            setArrowImageTemperatura(
              <img
                src={"https://carelhue.com.ar/img/dashboard/downarrow.png"}
                alt="Down Arrow"
                className="arrow-image"
              />
            );
          }
          if (diferenciaHumedad === 0) {
            setArrowImageHum(
              <img
                src={"https://carelhue.com.ar/img/dashboard/equal_arrow.png"}
                alt="Equal Arrow"
                className="arrow-image"
              />
            );
          } else if (diferenciaHumedad >= 0) {
            setArrowImageHum(
              <img
                src={"https://carelhue.com.ar/img/dashboard/uparrow.png"}
                alt="Up Arrow"
                className="arrow-image"
              />
            );
          } else if (diferenciaHumedad < 0) {
            setArrowImageHum(
              <img
                src={"https://carelhue.com.ar/img/dashboard/downarrow.png"}
                alt="Down Arrow"
                className="arrow-image"
              />
            );
          }

          if (diferenciaEC === 0) {
            setArrowImageEc(
              <img
                src={"https://carelhue.com.ar/img/dashboard/equal_arrow.png"}
                alt="Equal Arrow"
                className="arrow-image"
              />
            );
          } else if (diferenciaEC >= 0) {
            setArrowImageEc(
              <img
                src={"https://carelhue.com.ar/img/dashboard/uparrow.png"}
                alt="Up Arrow"
                className="arrow-image"
              />
            );
          } else if (diferenciaEC < 0) {
            setArrowImageEc(
              <img
                src={"https://carelhue.com.ar/img/dashboard/downarrow.png"}
                alt="Down Arrow"
                className="arrow-image"
              />
            );
          }
          if (diferenciaTank === 0) {
            setArrowImageTank(
              <img
                src={"https://carelhue.com.ar/img/dashboard/equal_arrow.png"}
                alt="Up Arrow"
                className="arrow-image"
              />
            );
          } else if (diferenciaTank >= 0) {
            setArrowImageTank(
              <img
                src={"https://carelhue.com.ar/img/dashboard/uparrow.png"}
                alt="Up Arrow"
                className="arrow-image"
              />
            );
          } else if (diferenciaTank < 0) {
            setArrowImageTank(
              <img
                src={"https://carelhue.com.ar/img/dashboard/downarrow.png"}
                alt="Down Arrow"
                className="arrow-image"
              />
            );
          }
          if (diferenciaTempSN === 0) {
            setArrowImageTempSN(
              <img
                src={"https://carelhue.com.ar/img/dashboard/equal_arrow.png"}
                alt="Up Arrow"
                className="arrow-image"
              />
            );
          } else if (diferenciaTempSN > 0) {
            setArrowImageTempSN(
              <img
                src={"https://carelhue.com.ar/img/dashboard/uparrow.png"}
                alt="Up Arrow"
                className="arrow-image"
              />
            );
          } else if (diferenciaTempSN < 0) {
            setArrowImageTempSN(
              <img
                src={"https://carelhue.com.ar/img/dashboard/downarrow.png"}
                alt="Down Arrow"
                className="arrow-image"
              />
            );
          }
          if (diferenciaLuz === 0) {
            setArrowImageLuz(
              <img
                src={"https://carelhue.com.ar/img/dashboard/equal_arrow.png"}
                alt="Up Arrow"
                className="arrow-image"
              />
            );
          } else if (diferenciaLuz > 0) {
            setArrowImageLuz(
              <img
                src={"https://carelhue.com.ar/img/dashboard/uparrow.png"}
                alt="Up Arrow"
                className="arrow-image"
              />
            );
          } else if (diferenciaLuz < 0) {
            setArrowImageLuz(
              <img
                src={"https://carelhue.com.ar/img/dashboard/downarrow.png"}
                alt="Down Arrow"
                className="arrow-image"
              />
            );
          }
        } else {
          console.log("error por acá");
          // Display default values when no data is found
          setUltimoRegistro({
            temp: 0,
            hum: 0,
            ec: 0,
            iluz: 0,
            tank: 0,
            temp_sn: 0,
            erelay1: 0,
            erelay2: 0,
            fechayhora: "No Data",
          });
          setUltimaSemana([]);
          setArrowImageTemperatura(null);
          setArrowImageHum(null);
          setArrowImageEc(null);
          setArrowImageLuz(null);
          setArrowImageTank(null);
          setArrowImageTempSN(null);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userName, macData, desde, hasta]);

  useEffect(() => {
    Ultimo();
  }, [Ultimo]);

  return (
    <div className="contenedorInicio">
      <div className="Dashtituloinicio">
        <div className="divInicio">
          <div>
            {loading ? (
              <div className="loading-container">
                <Loading type="spin" color="#0592F9" height={50} width={50} />
              </div>
            ) : (
              <div>
                {ultimoRegistro && (
                  <>
                    <div className="ultimosdatos">
                      <div className="itemultimosdatos">
                        <h2>Temperatura</h2>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="grafiquita">
                            <ResponsiveContainer width="100%" height={90}>
                              <LineChart data={ultimaSemana}>
                                <Line
                                  dataKey="temp"
                                  stroke="#0592F9"
                                  strokeWidth={2}
                                  name="Dato"
                                />
                                <XAxis dataKey="dia" height={5} tick={false} />
                                <YAxis domain={[0, 50]} />
                              </LineChart>
                            </ResponsiveContainer>
                          </div>
                          <strong style={{ whiteSpace: "nowrap" }}>
                            {ultimoRegistro.temp}°C {arrowImagetemp}
                          </strong>
                        </div>
                      </div>
                      <div className="itemultimosdatos">
                        <h2>Humedad</h2>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="grafiquita">
                            <ResponsiveContainer width="100%" height={90}>
                              <LineChart data={ultimaSemana}>
                                <Line
                                  dataKey="hum"
                                  stroke="#0592F9"
                                  strokeWidth={2}
                                  name="Dato"
                                />
                                <XAxis dataKey="dia" height={5} tick={false} />
                                <YAxis domain={[0, 100]} />
                              </LineChart>
                            </ResponsiveContainer>
                          </div>

                          <strong style={{ whiteSpace: "nowrap" }}>
                            {ultimoRegistro.hum}% {arrowImageHum}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="ultimosdatos">
                      <div className="itemultimosdatos">
                        <h2>EC</h2>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="grafiquita">
                            <ResponsiveContainer width="100%" height={90}>
                              <LineChart data={ultimaSemana}>
                                <Line
                                  dataKey="ec"
                                  stroke="#0592F9"
                                  strokeWidth={2}
                                  name="Dato"
                                />
                                <XAxis dataKey="dia" height={5} tick={false} />
                                <YAxis domain={[0, 4]} />
                              </LineChart>
                            </ResponsiveContainer>
                          </div>

                          <strong style={{ whiteSpace: "nowrap" }}>
                            {ultimoRegistro.ec}
                            <div
                              style={{
                                display: "inline-block",
                                margin: "0 5px",
                              }}
                            >
                              <div
                                style={{
                                  borderBottom: "3px solid white",
                                }}
                              >
                                <span style={{ fontSize: "20px" }}>mS</span>
                              </div>
                              <div>
                                <span style={{ fontSize: "20px" }}>cm</span>
                              </div>
                            </div>{" "}
                            {arrowImageEc}
                          </strong>
                        </div>
                      </div>

                      <div className="itemultimosdatos">
                        <h2>Luz</h2>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="grafiquita">
                            <ResponsiveContainer width="100%" height={90}>
                              <LineChart data={ultimaSemana}>
                                <Line
                                  dataKey="iluz"
                                  stroke="#0592F9"
                                  strokeWidth={2}
                                  name="Dato"
                                />
                                <XAxis dataKey="dia" height={5} tick={false} />
                                <YAxis domain={[0, 100]} />
                              </LineChart>
                            </ResponsiveContainer>
                          </div>

                          <strong style={{ whiteSpace: "nowrap" }}>
                            {ultimoRegistro.iluz}%{arrowImageLuz}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="ultimosdatos">
                      <div className="itemultimosdatos">
                        <h2>Nivel Tanque SN</h2>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="grafiquita">
                            <ResponsiveContainer width="100%" height={90}>
                              <LineChart data={ultimaSemana}>
                                <Line
                                  dataKey="tank"
                                  stroke="#0592F9"
                                  strokeWidth={2}
                                  name="Dato"
                                />
                                <XAxis dataKey="dia" height={5} tick={false} />
                                <YAxis domain={[0, 100]} />
                              </LineChart>
                            </ResponsiveContainer>
                          </div>

                          <strong style={{ whiteSpace: "nowrap" }}>
                            {ultimoRegistro.tank}%{arrowImageTank}
                          </strong>
                        </div>
                      </div>
                      <div className="itemultimosdatos">
                        <h2>Temp SN</h2>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="grafiquita">
                            <ResponsiveContainer width="100%" height={90}>
                              <LineChart data={ultimaSemana}>
                                <Line
                                  dataKey="temp"
                                  stroke="#0592F9"
                                  strokeWidth={2}
                                  name="Dato"
                                />
                                <XAxis dataKey="dia" height={5} tick={false} />
                                <YAxis domain={[0, 50]} />
                              </LineChart>
                            </ResponsiveContainer>
                          </div>

                          <strong style={{ whiteSpace: "nowrap" }}>
                            {ultimoRegistro.temp}ºC{arrowImageTempSN}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="ultimosdatos">
                      <div className="itemultimosdatos">
                        <h2>Irrigacion</h2>
                        <div>
                          <strong>{ultimoRegistro.irriga}</strong>
                          <div
                            style={{ display: "inline-block", margin: "0 5px" }}
                          >
                            <div
                              style={{
                                borderBottom: "3px solid white",
                              }}
                            >
                              <span style={{ fontSize: "20px" }}>min</span>
                            </div>
                            <div>
                              <span style={{ fontSize: "20px" }}>hora</span>
                            </div>
                          </div>{" "}
                          {parseInt(ultimoRegistro.erelay1) === 1 ? (
                            <FontAwesomeIcon
                              icon={faPlugCircleCheck}
                              className="Enchufeimage"
                              style={{ color: "green" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faPlugCircleXmark}
                              className="Enchufeimage"
                              style={{ color: "red" }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="itemultimosdatos">
                        <h2>Aireación</h2>
                        <div>
                          <strong>{ultimoRegistro.irriga}</strong>
                          <div
                            style={{ display: "inline-block", margin: "0 5px" }}
                          >
                            <div
                              style={{
                                borderBottom: "3px solid white",
                              }}
                            >
                              <span style={{ fontSize: "20px" }}>min</span>
                            </div>
                            <div>
                              <span style={{ fontSize: "20px" }}>hora</span>
                            </div>
                          </div>{" "}
                          {parseInt(ultimoRegistro.erelay2) === 1 ? (
                            <FontAwesomeIcon
                              icon={faPlugCircleCheck}
                              className="Enchufeimage"
                              style={{ color: "green" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faPlugCircleXmark}
                              className="Enchufeimage"
                              style={{ color: "red" }}
                            />
                          )}
                        </div>
                      </div>

                      <div className="itemultimosdatos">
                        <h2>Ultimo Registro</h2>
                        <strong>
                          {" "}
                          <span style={{ fontSize: "0.8em" }}>
                            {ultimoRegistro.fechayhora}
                          </span>
                        </strong>
                      </div>
                      <div className="itemultimosdatos">
                        <div>
                          <h2 style={{ textAlign: "left", marginLeft: "10%" }}>
                            Usuario:{" "}
                            <strong style={{ color: "#ffffff" }}>
                              <span style={{ fontSize: "0.8em" }}>
                                {userName}
                              </span>{" "}
                            </strong>
                          </h2>
                        </div>
                        <div>
                          <h2 style={{ textAlign: "left", marginLeft: "10%" }}>
                            Equipo:{" "}
                            <strong style={{ color: "#ffffff" }}>
                              <span style={{ fontSize: "0.9em" }}>
                                {macData}{" "}
                              </span>
                            </strong>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="footVerde">
          <p>CARELHUE - Tecnologia en hidroponia </p>
        </div>
      </div>
    </div>
  );
};

export default Inicio;
