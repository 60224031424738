import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./carousel.css";

export const Codigo = (props) => {
  const renderThumbs = () => {
    return props.data.map((item, index) => {
      const miniaturas = Object.keys(item)
        .filter((key) => key.startsWith("img"))
        .map((key) => item[key]);

      return (
        <div
          key={index}
          className={`custom-thumbnail ${index === 0 ? "active" : ""}`}
          onClick={() => carousel && carousel.select(index)}
        >
          {miniaturas.map((miniatura, miniaturaIndex) => (
            <img
              key={miniaturaIndex}
              src={miniatura}
              alt={`Thumbnail ${miniaturaIndex}`}
              className="img-responsive"
              style={{
                borderRadius: "8px",
              }}
            />
          ))}
        </div>
      );
    });
  };

  let carousel;

  return (
    <div id="codigo" className="text-center">
      <div className="codigoback">
        <br />
        <br />
        <br />
        <br />
        <div className="section-title">
          <h2 style={{ textAlign: "center" }}>Codigo Fuente</h2>
        </div>
        <div className="container">
          <div className="row">
            {props.data ? (
              props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className="col-md-12"
                  style={{
                    margin: "8px 10px 7px",
                    height: "100%",
                    borderRadius: "18px",
                    background: "#d0dcf8",
                  }}
                >
                  <br />
                  <h3>{d.title}</h3>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ width: "80%", textAlign: "center" }}>
                      <Carousel
                        showThumbs={false}
                        dynamicHeight={false}
                        selectedItem={0}
                        renderThumbs={renderThumbs}
                        ref={(el) => (carousel = el)}
                        style={{ width: "80%" }}
                      >
                        <div className="slide">
                          <img
                            src={d.img}
                            alt={d.alt}
                            className="img-responsive"
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img0}
                            alt={d.alt0}
                            className="img-responsive"
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend0}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img1}
                            className="img-responsive"
                            alt={d.alt1}
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend1}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img2}
                            className="img-responsive"
                            alt={d.alt2}
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend2}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img3}
                            className="img-responsive"
                            alt={d.alt3}
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend3}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img4}
                            className="img-responsive"
                            alt={d.alt4}
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend4}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img5}
                            className="img-responsive"
                            alt={d.alt5}
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend5}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img6}
                            className="img-responsive"
                            alt={d.alt6}
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend6}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img7}
                            className="img-responsive"
                            alt={d.alt7}
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend7}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img8}
                            className="img-responsive"
                            alt={d.alt8}
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend8}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img9}
                            className="img-responsive"
                            alt={d.alt9}
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend9}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img10}
                            className="img-responsive"
                            alt={d.alt10}
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend10}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img11}
                            className="img-responsive"
                            alt={d.alt11}
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend11}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img12}
                            className="img-responsive"
                            alt={d.alt12}
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend12}</p>
                        </div>
                        <div className="slide">
                          <img
                            src={d.img13}
                            className="img-responsive"
                            alt={d.alt13}
                            style={{
                              borderRadius: "18px",
                              height: "500px",
                              width: "auto",
                            }}
                          />
                          <p className="legend">{d.legend13}</p>
                        </div>
                      </Carousel>
                    </div>
                  </div>
                  <p
                    style={{
                      textAlign: "justify",
                      marginLeft: "3%",
                      marginRight: "3%",
                    }}
                  >
                    {props.data && d.text ? (
                      <div dangerouslySetInnerHTML={{ __html: d.text }} />
                    ) : (
                      "Cargando..."
                    )}
                  </p>
                  <p
                    style={{
                      textAlign: "justify",
                      marginLeft: "3%",
                      marginRight: "3%",
                    }}
                  >
                    {props.data && d.text1 ? (
                      <div dangerouslySetInnerHTML={{ __html: d.text1 }} />
                    ) : (
                      "Cargando..."
                    )}
                  </p>
                  <p
                    style={{
                      textAlign: "justify",
                      marginLeft: "3%",
                      marginRight: "3%",
                    }}
                  >
                    {props.data && d.text2 ? (
                      <div dangerouslySetInnerHTML={{ __html: d.text2 }} />
                    ) : (
                      "Cargando..."
                    )}
                  </p>
                  <p
                    style={{
                      textAlign: "justify",
                      marginLeft: "3%",
                      marginRight: "3%",
                    }}
                  >
                    {props.data && d.text3 ? (
                      <div dangerouslySetInnerHTML={{ __html: d.text3 }} />
                    ) : (
                      "Cargando..."
                    )}
                  </p>
                  <p
                    style={{
                      textAlign: "justify",
                      marginLeft: "3%",
                      marginRight: "3%",
                    }}
                  >
                    {props.data && d.text4 ? (
                      <div dangerouslySetInnerHTML={{ __html: d.text4 }} />
                    ) : (
                      "Cargando..."
                    )}
                  </p>
                  <p
                    style={{
                      textAlign: "justify",
                      marginLeft: "3%",
                      marginRight: "3%",
                    }}
                  >
                    {props.data && d.text5 ? (
                      <div dangerouslySetInnerHTML={{ __html: d.text5 }} />
                    ) : (
                      "Cargando..."
                    )}
                  </p>
                  <p
                    style={{
                      textAlign: "justify",
                      marginLeft: "3%",
                      marginRight: "3%",
                    }}
                  >
                    {props.data && d.text6 ? (
                      <div dangerouslySetInnerHTML={{ __html: d.text6 }} /> 
                    ) : (
                      "Cargando..."
                    )}
                  </p>
                  <br />
                </div>
              ))
            ) : (
              <p>Cargando...</p>
            )}
          </div>
          <br />
          <br />
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};
