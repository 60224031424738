import React, { useEffect, useState, useCallback } from "react";
import Loading from "react-loading";
import "./consulta.css";

const Consulta = () => {
  const [loading, setLoading] = useState(true);
  //const userName = localStorage.getItem("userName");
  const macData = localStorage.getItem("macData");
  const [pregunta, setConsulta] = useState([]);
  const [topico, setTopico] = useState("");
  const [consulta, setConsultaTexto] = useState("");
  const [mail, setMail] = useState("");
  const [mostrarFormulario, setMostrarFormulario] = useState(false);

  const cero = 0;

  const TablaConsulta = useCallback(() => {
    setLoading(true);
    console.log("INICIO: MAC" + macData);

    // fetch("http://localhost/carelhue/php/listallconsulta.php", {
    fetch("https://www.carelhue.com.ar/php/listallconsulta.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ preg: cero }),
    })
      .then((cons) => cons.json())
      .then((data) => {
        const cons = data;
        setConsulta(cons);
        console.log("DATA pregunta ==>> ");
        console.log(JSON.stringify(cons));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [macData]);

  useEffect(() => {
    TablaConsulta();
  }, [TablaConsulta]);

  const handleTopicoChange = (e) => {
    setTopico(e.target.value);
  };

  const handleConsultaChange = (e) => {
    setConsultaTexto(e.target.value);
  };

  const handleMailChange = (e) => {
    setMail(e.target.value);
  };

  const abrirFormulario = () => {
    setMostrarFormulario(true);
  };

  const cerrarFormulario = () => {
    setMostrarFormulario(false);
  };

  const enviarConsulta = () => {
    // Realizar la solicitud POST al servidor
    fetch("https://www.carelhue.com.ar/php/insertconsulta.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        topico: topico,
        consulta: consulta,
        mail: mail,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Aquí puedes realizar acciones después de enviar los datos, si es necesario
        console.log("Datos enviados correctamente:", data);
        // Después de guardar los datos, puedes cerrar el formulario
        cerrarFormulario();
      })
      .catch((error) => {
        // Manejar errores de la solicitud
        console.error("Error al enviar los datos:", error);
      });
  };

  return (
    <div className="containerInicio">
      <div>
        <h4>CONSULTAS<i className="fas fa-question-circle" style={{paddingLeft:"20px", color:"#43099a"}}></i></h4>
        {/* <div>
          <p>
            <h5>
              Bienvenido {userName} Tu equipo es: {macData}
            </h5>
          </p>
        </div> */}
        <br />
        <div style={{ backgroundColor: "#FFFF" , borderRadius: "10px", boxShadow:"5px 5px 5px rgba(0, 255, 42, 0.582)"}}>
          <br />
          <p>Las consultas serán respondidas a la mayor brevedad posible</p>
          <br />
          {mostrarFormulario ? (
            <div id="formulario">
              <p>
                Selecciona el topico de la consulta:{" "}
                <select value={topico} onChange={handleTopicoChange}>
                  <option>Canales de Riego</option>
                  <option>Nutricion</option>
                  <option>Niveles</option>
                  <option>Semillas</option>
                  <option>Germinacion</option>
                  <option>Transplante</option>
                  <option>Sistema</option>
                  <option>Otros</option>
                </select>
              </p>

              <p>
                <textarea
                  name="consult"
                  cols="40"
                  rows="4"
                  value={consulta}
                  onChange={handleConsultaChange}
                  placeholder="Ingrese su consulta..."
                ></textarea>
              </p>

              <br />
              <p>
                Mail:
                <input
                  name="mail_add"
                  type="text"
                  size="40"
                  value={mail}
                  onChange={handleMailChange}
                />
              </p>
              <br />
              <button onClick={enviarConsulta}>Enviar</button>
              <button onClick={cerrarFormulario}>Cancelar</button>
              <br></br>
            </div>
          ) : (
            <button classname="buttonBlue" onClick={abrirFormulario}>
              Hacer Consulta
              <br />
            </button>
          )}
          <br />
          <p><br/></p>
        </div>

        <div>
          <div
            className="itemlastInicio"
            style={{
              border: "2px solid #D1FAD0",
              backgroundColor: "#D1FAD0",
            }}
          >
            <h4>Consultas</h4>
            <div>
              {loading ? (
                <div className="loading-container">
                  <Loading type="spin" color="#0592F9" height={50} width={50} />
                </div>
              ) : (
                <div>
                  {pregunta && (
                    <>
                      <div>
                        <table style={{ width: "100%" }}>
                          {pregunta.map((each, index) => (
                            <tbody key={index}>
                              <tr>
                                <td
                                  style={{
                                    width: "25%",
                                    backgroundColor: "#FFFF",
                                  }}
                                >
                                  Topico
                                </td>
                                <td style={{ width: "75%" }}>{each.topico}</td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    width: "25%",
                                    backgroundColor: "#FFFF",
                                  }}
                                >
                                  Consulta
                                </td>
                                <td style={{ width: "75%" }}>
                                  {each.consulta}
                                </td>
                              </tr>
                              <tr style={{ borderBottom: "2px solid #000000" }}>
                                <td
                                  style={{
                                    width: "25 %",
                                    backgroundColor: "#FFFF",
                                  }}
                                >
                                  Respuesta
                                </td>
                                <td
                                  style={{
                                    width: "80%",
                                  }}
                                >
                                  {each.respuesta}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            <br/>
          </div>
          <br/>
        </div>
        
      </div>
      <br/>
      <br/>
      <div className="footer">
        <div style={{ backgroundColor: "#1CD10E" }}>
          <p
            style={{ textAlign: "center", fontSize: "24px", color: "#FFFFFF" }}
          >
            CARELHUE - Tecnologia en hidroponia
          </p>
        </div>
      </div>
    </div>
  );
};

export default Consulta;
