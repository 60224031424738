import React, { useCallback, useEffect, useState } from "react";
import Loading from "react-loading";
import "./vivero.css";

const Vivero = () => {
  const [loading, setLoading] = useState(false);
  const userName = localStorage.getItem("userName");
  const macData = localStorage.getItem("macData");
  const cero = 0;
  const [tablavivero, setVivero] = useState([]);
  const [mensaje, setMensaje] = useState("");

  const TablaVivero = useCallback(() => {
    setLoading(true);
    //fetch("http://localhost/carelhue/php/listallvivero.php", {
    fetch("https://www.carelhue.com.ar/php/listallvivero.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ list: cero }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const viv = data.vivero;
          setVivero(viv);
          console.log("DATA vivero ==>> ");
          console.log(viv);
        } else {
          setMensaje(data.mensaje);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    TablaVivero();
  }, [TablaVivero]);

  const mostrarHoja = (nombrePlanta) => {
    // Obtener la imagen correspondiente a la planta
    const hojaImg = `https://www.carelhue.com.ar/img/vivero/${nombrePlanta}.jpg`;

    // Crear una ventana emergente para mostrar la imagen
    const ventanaEmergente = window.open(
      "",
      "Imagen de la hoja",
      "width=400,height=400"
    );

    // Crear un elemento de imagen para cargar la imagen de la planta
    const img = new Image();
    img.style.maxWidth = "100%"; // Ajustar la imagen al tamaño máximo de la ventana emergente
    img.style.maxHeight = "100%"; // Ajustar la imagen al tamaño máximo de la ventana emergente

    // Crear un botón para cerrar la ventana emergente
    const closeButton = document.createElement("button");
    closeButton.innerHTML = '<i class="fas fa-times"></i> Cerrar';
    closeButton.onclick = () => ventanaEmergente.close();

    img.onload = function () {
      // La imagen existe, mostrarla en la ventana emergente
      ventanaEmergente.document.body.innerHTML = "";
      ventanaEmergente.document.body.appendChild(img);
      ventanaEmergente.document.body.appendChild(closeButton);
    };

    img.onerror = function () {
      // La imagen no existe, mostrar la imagen predeterminada en la ventana emergente
      const imagenPredeterminada =
        "https://www.carelhue.com.ar/img/vivero/noimage.jpg";
      const imgPredeterminada = new Image();
      imgPredeterminada.src = imagenPredeterminada;
      imgPredeterminada.style.maxWidth = "100%"; // Ajustar la imagen al tamaño máximo de la ventana emergente
      imgPredeterminada.style.maxHeight = "100%"; // Ajustar la imagen al tamaño máximo de la ventana emergente

      ventanaEmergente.document.body.innerHTML = "";
      ventanaEmergente.document.body.appendChild(imgPredeterminada);
      ventanaEmergente.document.body.appendChild(closeButton);
    };

    img.src = hojaImg;
  };

  return (
    <div>
      <div className="contenedorVivero">
        <div>
          <h4>
            VIVERO
            <i
              className="fas fa-leaf"
              style={{ paddingLeft: "20px", color: "green" }}
            ></i>
          </h4>
          <div>
            <h5>
              Bienvenido {userName} Tu equipo es: {macData}
            </h5>
          </div>
          <div>
            <div className="itemlastVivero">
              <h4>Tabla Vivero ({tablavivero.length} plantas cargadas)</h4>
              <p>{mensaje}</p>
              <div>
                {loading ? (
                  <div className="loading-container">
                    <Loading
                      type="spin"
                      color="#0592F9"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <div className="table-container">
                    {tablavivero && (
                      <>
                        <div className="table-responsive">
                          <table className="table">
                            <thead></thead>

                            {tablavivero.map((each, index) => (
                              <tbody key={index}>
                                <tr
                                  colSpan="7"
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#90FF87", // Color de fondo azul
                                  }}
                                >
                                  <td
                                    colSpan="7"
                                    id={`nombrePlanta-${each.nombre_planta}`}
                                    style={{
                                      fontSize: "22px",
                                      fontWeight: "bold",
                                      color: "blue",
                                      width: "100%",
                                    }}
                                    title={each.nombre_planta}
                                  >
                                    {each.nombre_planta}
                                  </td>
                                  <td colSpan="1">
                                    <img
                                      src={`https://www.carelhue.com.ar/img/vivero/${each.nombre_planta}.jpg`}
                                      alt={`Imagen miniatura de ${each.nombre_planta}`}
                                      style={{
                                        width: "60px",
                                        height: "40px",
                                        marginRight: "2px",
                                      }}
                                      onError={(e) => {
                                        e.target.onerror = null; // Evitar bucles infinitos en caso de que "noimage.jpg" también esté rota
                                        e.target.src =
                                          "https://www.carelhue.com.ar/img/vivero/noimage.jpg"; // Cargar la imagen "noimage.jpg" cuando ocurra un error
                                      }}
                                      onClick={() =>
                                        mostrarHoja(each.nombre_planta)
                                      }
                                      title={`Mostrar imagen de ${each.nombre_planta}`}
                                    />
                                  </td>
                                </tr>
                                <tr style={{ width: "100%" }}>
                                  {/* <th colSpan="1"> id</th> */}
                                  <th colSpan="2"> Tipo</th>
                                  <th colSpan="2"> Estacion</th>
                                  <th colSpan="4"> Vitaminas</th>
                                </tr>
                                <tr>
                                  {/* <td> {each.Id_vivero}</td> */}

                                  <td colSpan="2"> {each.tipo}</td>
                                  <td colSpan="2"> {each.estacion}</td>
                                  <td colSpan="4"> {each.vitamina}</td>
                                </tr>
                                <tr>
                                  <th style={{ width: "12.5%" }}> Germina</th>
                                  <th
                                    style={{
                                      width: "12.5%",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {" "}
                                    EC
                                  </th>
                                  <th style={{ width: "12.5%" }}> Crece</th>
                                  <th
                                    style={{
                                      width: "12.5%",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {" "}
                                    EC
                                  </th>
                                  <th style={{ width: "12.5%" }}> Poliniza</th>
                                  <th
                                    style={{
                                      width: "12.5%",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {" "}
                                    EC
                                  </th>
                                  <th style={{ width: "12.5%" }}> Fruto</th>
                                  <th style={{ width: "12.5%" }}> EC</th>
                                </tr>
                                <tr>
                                  <td style={{ width: "12.5%" }}>
                                    {" "}
                                    {each.germina !== "-"
                                      ? `${each.germina} días`
                                      : "-"}
                                  </td>
                                  <td
                                    style={{
                                      borderRight: "1px solid black",
                                      width: "12.5%",
                                    }}
                                  >
                                    {" "}
                                    {each.ec_germina !== "-"
                                      ? `${each.ec_germina} mS`
                                      : "-"}
                                  </td>
                                  <td style={{ width: "12.5%" }}>
                                    {" "}
                                    {each.crece !== "-"
                                      ? `${each.crece} `
                                      : "-"}
                                  </td>
                                  <td
                                    style={{
                                      borderRight: "1px solid black",
                                      width: "12.5%",
                                    }}
                                  >
                                    {each.ec_crece !== "-"
                                      ? `${each.ec_crece} mS`
                                      : "-"}
                                  </td>
                                  <td style={{ width: "12.5%" }}>
                                    {each.poliniza !== "-"
                                      ? `${each.poliniza}`
                                      : "-"}
                                  </td>
                                  <td
                                    style={{
                                      borderRight: "1px solid black",
                                      width: "12.5%",
                                    }}
                                  >
                                    {" "}
                                    {each.ec_poliniza !== "-"
                                      ? `${each.ec_poliniza} mS`
                                      : "-"}
                                  </td>
                                  <td style={{ width: "12.5%" }}>
                                    {" "}
                                    {each.fruto !== "-"
                                      ? `${each.fruto} `
                                      : "-"}
                                  </td>
                                  <td style={{ width: "12.5%" }}>
                                    {" "}
                                    {each.ec_fruto !== "-"
                                      ? `${each.ec_fruto} mS`
                                      : "-"}
                                  </td>
                                </tr>
                                <tr style={{ width: "100%" }}>
                                  <th colSpan="8"> Observaciones</th>
                                </tr>
                                <tr style={{ width: "100%" }}>
                                  <td colSpan="8"> {each.observacion1}</td>
                                </tr>
                                <tr
                                  style={{
                                    borderBottom: "2px solid black",
                                    width: "100%",
                                  }}
                                >
                                  <td colSpan="8"> {each.observacion2}</td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <h5>
            Datos sacados de hechos facticos, empiricos y en una variedad de
            fuentes literarias
          </h5>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
      <br />
      <div className="footer">
        <div style={{ backgroundColor: "#1CD10E" }}>
          <p
            style={{ textAlign: "center", fontSize: "24px", color: "#FFFFFF" }}
          >
            CARELHUE - Tecnologia en hidroponia
          </p>
        </div>
      </div>
    </div>
  );
};

export default Vivero;
