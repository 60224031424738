import React, { useCallback, useEffect, useState } from "react";
import Loading from "react-loading";
import "./descarga.css";

const Descarga = () => {
  const [loading, setLoading] = useState(true);
  const [archivosDescarga, setArchivosDescarga] = useState([]);
  const cero = 0;

  const obtenerArchivosDescarga = useCallback(async () => {
    setLoading(true);

    try {
      const response = await fetch("https://www.carelhue.com.ar/php/listalldescargas.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ preg: cero }),
      });

      const data = await response.json();
      setArchivosDescarga(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [cero]);


  const handleDownload = async (idDescargas) => {
    try {
      console.log("ID a actualizar:", idDescargas); // Agregamos este console.log
      const response = await fetch("https://www.carelhue.com.ar/php/list1descargas.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ idToUpdate: idDescargas }),
      });
  
      const result = await response.json();
  
      if (result.success) {
        console.log(`Descargando archivo con ID: ${idDescargas}`);
      } else {
        console.log("Error al actualizar el contador de descargas.");
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  

  
  
  
  

  useEffect(() => {
    obtenerArchivosDescarga();
  }, [obtenerArchivosDescarga]);


  return (
    <div className="containerDescarga">
      <div>
        <h4>
          DESCARGAS
          <i
            className="fas fa-download"
            style={{ paddingLeft: "20px", color: "#0c5da1" }}
          ></i>{" "}
        </h4>
        <br />
        <div>
          <div
            className="itemlastInicio"
            style={{
              border: "2px solid #D1FAD0",
              backgroundColor: "#D1FAD0",
            }}
          >
            {loading ? (
              <div className="loading-container">
                <Loading type="spin" color="#0592F9" height={50} width={50} />
              </div>
            ) : (
              <div className="archivos-container">
                {archivosDescarga.map((archivo, index) => (
                  <div key={index} className="archivoDescarga">
                    <a
                      href={archivo.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      onClick={() => handleDownload(archivo.Id_descargas)}
                    >
                      <i
                        className="fas fa-download"
                        style={{ color: "#0c5da1" }}
                        onMouseOver={() => console.log(`ID_descargas: ${archivo.Id_descargas}`)}
                      ></i>
                    </a>
                    <div className="archivo-info">
                      <p className="archivo-nombre">
                        {archivo.archivo}{" "}
                        {archivo.icono === "pdf" ? (
                          <i className="fas fa-file-pdf"></i> // Icono para PDF
                        ) : archivo.icono === "txt" ? (
                          <i className="fas fa-file-alt"></i> // Icono para TXT
                        ) : archivo.icono === "bat" ? (
                          <i className="fas fa-code"></i> // Icono para bat
                        ) : archivo.icono === "rar" ? (
                          <i className="fas fa-file-archive"></i> // Icono para rar
                        ) : archivo.icono === "ino" ? (
                          <i className="fab fa-sketch"></i> // Icono para INO
                        ) : archivo.icono === "apk" ? (
                          <i className="fab fa-android"></i> // Icono para APK
                        ) : (
                          <i className="fas fa-file"></i> // Icono genérico si no coincide
                        )}
                      </p>

                      <p className="archivo-descripcion">
                        {archivo.descripcion}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <br />
      <div className="footer">
        <div style={{ backgroundColor: "#1CD10E" }}>
          <p
            style={{ textAlign: "center", fontSize: "24px", color: "#FFFFFF" }}
          >
            CARELHUE - Tecnologia en hidroponia
          </p>
        </div>
      </div>
    </div>
  );
};

export default Descarga;